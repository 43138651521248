import React from "react";
import one from "../assets/one.JPG";
import two from "../assets/two.JPG";
import three from "../assets/three.JPG";
import four from "../assets/four.JPG";
import five from "../assets/five.JPG";
import { NavLink } from "react-router-dom";

const About = () => {
  return (
    <div className="flex flex-col items-center justify-start">
      <img src={one} />

      <NavLink to="/process-of-science">
        <img src={two} className="hover:cursor-pointer" />
      </NavLink>
      <img src={three} />
      <img src={four} />
      <img src={five} />
    </div>
  );
};

export default About;
