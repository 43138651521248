import { styled, Box, Button, Typography } from "@mui/material";

export const ServiceWrapper = styled(Box)`
  margin-top: 28em;
  @media (max-width:650px){
    margin-top: 12em;
  }
`;

export const ServicesContainer = styled(Box)`
font-family: "Inter",sans-serif;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 90%;
  margin: 4em auto;
  padding: 1em;
  @media (max-width:650px){
    flex-direction: column;
    width: 100%;
  }
`;

export const ServiceMainText = styled(Typography)`
  font-size: 4.5rem;
  font-family: "Inter", sans-serif;
  color: rgba(255, 255, 255, 1);
  font-weight: 700;
  text-align: center;
  text-transform: capitalize;
  @media (max-width:650px){
    font-size: 3.2rem;
   text-align: center;
  }
`;

export const SpeedBox = styled(Box)`
  width: 32%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width:650px){
    width: 100%;
    margin: 2em 0;
  }
`;

export const SpeedText = styled(Typography)`
  font-size: 2.2rem;
  margin-top: 2em;
  font-family: "Poppins", sans-serif;
  color: rgba(255, 255, 255, 1);
  letter-spacing: 2px;
  @media (max-width:650px){
    font-size: 3rem;
    text-align: center;
    margin-top: 1em;
  }
`;

export const PromoteBox = styled(Box)`
  width: 32%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width:650px){
    width: 100%;
    margin: 2em 0;
  }
`;

export const PromoteText = styled(Typography)`
  font-size: 2.2rem;
  margin-top: 2em;
  font-family: "Poppins", sans-serif;
  color: rgba(255, 255, 255, 1);
  letter-spacing: 2px;
  @media (max-width:650px){
    font-size: 3rem;
    text-align: center;
    margin-top: 1em;
  }
`;

export const ExperienceBox = styled(Box)`
  width: 32%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width:650px){
    width: 100%;
    margin: 2em 0;
  }
`;

export const ExperienceText = styled(Typography)`
  font-size: 2.2rem;
  margin-top: 2em;
  font-family: "Poppins", sans-serif;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  letter-spacing: 2px;
  @media (max-width:650px){
    font-size: 3rem;
    text-align: center;
    margin-top: 1em;
  }
`;
