import { styled, Box, Typography, Button } from "@mui/material";

export const HeroContainer = styled(Box)`
  font-family: "Inter", sans-serif;
  color: rgba(255, 255, 255, 1);
`;

export const HeroMainText = styled(Typography)`
  margin: 1em 0;
  font-weight: 700;
  margin-left: 0.4em;
  margin-top: 1.5em;
  font-size: 5.5rem;
  @media (max-width:650px){
    font-size: 2.8rem;
    font-weight: bold;
  }
`;

export const LearnCommunityContainer = styled(Box)`
  display: flex;
  justify-content: space-center;
  align-items: center;
  padding: 1em;
  margin-top: 10em;
  @media (max-width:650px){
    flex-direction: column;
  }
`;

export const LearnContainer = styled(Box)`
  display: flex;
  align-items: center;
  width: 40%;
  flex-direction: column;
  @media (max-width:650px){
    width: 100%;
  }
`;

export const LearnText = styled(Typography)`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  min-width: 500px;
  font-size: 3.2rem;
`;

export const ExpertText = styled(Typography)`
  margin-top: 0.5em;
  font-weight: 700;
  letter-spacing: 2px;
`;

export const CommunityContainer = styled(Box)`
  display: flex;
  align-items: center;
  width: 60%;
  flex-direction: column;
  @media (max-width:650px){
    width: 100%;
    margin-top: 5em;
  }
`;

export const CommunityText = styled(Typography)`
  display: flex;
  align-items: center;
  flex-direction: column;
  font-weight: 700;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 3.2rem;
  @media (max-width:650px){
    font-size: 1.5rem;
  }
`;

export const JoinButton = styled(Button)`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 1);
  font-size: 1.5rem;
  background: linear-gradient(101.61deg, #84eef5 -14.72%, #c584f5 66.97%);
  font-family: "Poppins", sans-serif;
  border-radius: 10px;
  text-transform: uppercase;
  margin-top: 2em;
  font-weight: 700;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  letter-spacing: 1px;
  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    transition: all 0.5s;
    box-shadow: 0 0 10px 40px white;
  }
  &:active::after {
    box-shadow: 0 0 0 0 white;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 1;
    transition: 0s;
  }
  &:active {
    top: 1px;
  }
  @media (max-width:650px){
    width: 80%;;
  }
`;

export const TryButton = styled(Button)`
  position: relative;
  width: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(255, 255, 255, 1);
  background: linear-gradient(101.61deg, #84eef5 -14.72%, #c584f5 66.97%);
  font-family: "Poppins", sans-serif;
  border-radius: 10px;
  text-transform: uppercase;
  margin-top: 2em;
  font-weight: 700;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 0px 16px;
  letter-spacing: 1px;
  user-select: none;
  transition-duration: 0.4s;
  font-size: 1.5rem;
  -webkit-transition-duration: 0.4s; /* Safari */
  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 0;
    width: 100%;
    height: 100%;
    transition: all 0.5s;
    box-shadow: 0 0 10px 40px white;
  }
  &:active::after {
    box-shadow: 0 0 0 0 white;
    position: absolute;
    left: 0;
    top: 0;
    opacity: 1;
    transition: 0s;
  }
  &:active {
    top: 1px;
  }
  @media (max-width:650px){
    width: 80%;;
  }
`;
