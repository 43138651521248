import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userActions } from "../../store/userSlice";
import useInput from "../../hooks/useInput-hook";
import { BaseURL } from "../../utils/config";

// login-specific imports
import GoogleLogin from "react-google-login";
import { client } from "../../client";

// custom components
import FormCard from "../UI/FormCard";
import FormInput from "../UI/FormInput";
import Button from "../UI/Button";
import FormRadioInput from "../UI/FormRadioInput";

// icon libraries
import { FcGoogle } from "react-icons/fc";
import { ImSpinner } from "react-icons/im";
import logo from "../../assets/logo.png";

const MINIMUM_PASS_LENGTH = 7;
const MINIMUM_NAME_LENGTH = 1;

// validation functions FIXME, it should be an optimal way for these functions

const validateEmail = (email) => {
  //const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const regex = /^[a-zA-Z0-9.-1.]+@[a-zA-Z0-9.]+\.[A-Za-z]+$/;
  return regex.test(email).valueOf();
};

const validatePassword = (password) => {
  return password.trim().length >= MINIMUM_PASS_LENGTH;
};

const validateName = (name) => {
  return name.trim().length >= MINIMUM_NAME_LENGTH;
};

const validateConfirmPassword = (confirmPassword, password) => {
  return (
    confirmPassword.trim().length >= MINIMUM_PASS_LENGTH &&
    confirmPassword === password
  );
};

// TODO if the user entered an already existed email
const generateEmailError = (email) => {
  const errorMessage =
    email.trim().length === 0
      ? "Please enter your email address."
      : "That's an invalid email.";
  return errorMessage;
};

const generatePasswordError = (password) => {
  const errorMessage =
    password.trim().length === 0
      ? "Please enter a password."
      : `Password must be at least ${MINIMUM_PASS_LENGTH} characters.`;
  return errorMessage;
};

const generateFirstnameError = (firstname) => {
  return firstname.trim().length === 0 ? "Please enter your first name." : "";
};

const generateLastnameError = (lastname) => {
  return lastname.trim().length === 0 ? "Please enter your last name." : "";
};

const generateConfirmPasswordError = (confirmPassword) => {
  return confirmPassword.trim().length === 0
    ? "Please re-enter your password."
    : "Not match.";
};

// funtional component
function SignupForm() {
  // redux-toolkit
  const dispatch = useDispatch();

  const emailRef = useRef();

  const navigate = useNavigate();

  // form state
  const [isLoading, setIsLoading] = useState(false);
  const [errorState, setError] = useState("");

  // input states
  const [isResearcher, setIsResearcher] = useState(false);

  const { inputState: email, dispatchInput: dispatchEmail } = useInput(
    validateEmail,
    generateEmailError
  );

  const { inputState: password, dispatchInput: dispatchPassword } = useInput(
    validatePassword,
    generatePasswordError
  );

  const { inputState: firstname, dispatchInput: dispatchFirstname } = useInput(
    validateName,
    generateFirstnameError
  );

  const { inputState: lastname, dispatchInput: dispatchLastname } = useInput(
    validateName,
    generateLastnameError
  );

  const {
    inputState: confirmPassword,
    dispatchInput: dispatchConfirmPassword,
  } = useInput(validateConfirmPassword, generateConfirmPasswordError);

  // handler functions
  const emailHandler = (e) => {
    dispatchEmail({ type: "USER_INPUT", value: e.target.value });
  };

  const firstnameHandler = (e) => {
    dispatchFirstname({ type: "USER_INPUT", value: e.target.value });
  };

  const lastnameHandler = (e) => {
    dispatchLastname({ type: "USER_INPUT", value: e.target.value });
  };

  const passwordHandler = (e) => {
    dispatchPassword({ type: "USER_INPUT", value: e.target.value });
  };

  const confirmPasswordHandler = (e) => {
    dispatchConfirmPassword({
      type: "USER_INPUT",
      value: e.target.value,
      passwordInputValue: password.value,
    });
  };

  // make sure the last entered password is sent to confirm password
  useEffect(() => {
    if (confirmPassword.isTouched) {
      dispatchConfirmPassword({
        type: "USER_INPUT",
        value: confirmPassword.value,
        passwordInputValue: password.value,
      });
    }
  }, [
    dispatchConfirmPassword,
    password.value,
    confirmPassword.value,
    confirmPassword.isTouched,
  ]);

  const radionInputChangeHandler = (value) => {
    setIsResearcher(value);
  };

  //  form submit handler
  const formSubmitHandler = async (e) => {
    e.preventDefault();

    if (!email.isValid) {
      dispatchEmail({ type: "INVALID_INPUT" });
    }

    if (!firstname.isValid) {
      dispatchFirstname({ type: "INVALID_INPUT" });
    }

    if (!lastname.isValid) {
      dispatchLastname({ type: "INVALID_INPUT" });
    }

    if (!password.isValid) {
      dispatchPassword({ type: "INVALID_INPUT" });
    }

    if (!confirmPassword.isValid) {
      dispatchConfirmPassword({ type: "INVALID_INPUT" });
    }

    if (
      !email.isValid ||
      !firstname.isValid ||
      !lastname.isValid ||
      !password.isValid ||
      !confirmPassword.isValid
    )
      return;

    // form is valid, now create the user on sanity and get the token
    try {
      setIsLoading(true);
      const res = await fetch(`${BaseURL}/auth/register`, {
        method: "POST",
        body: JSON.stringify({
          email: email.value.toLowerCase(),
          password: password.value,
          firstname: firstname.value,
          lastname: lastname.value,
          isResearcher: isResearcher,
        }),
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": true,
          "Access-Control-Allow-Credentials": true,
        },
      });
      setIsLoading(false);

      //  Show a message when something went wrong
      if (!res.ok) {
        const { error: errorMessage } = await res.json();
        throw new Error(errorMessage);
      }

      const data = await res.json();
      dispatch(userActions.login(data));
      localStorage.setItem("user-token", data.token);
      navigate("/", {
        replace: true,
      });
    } catch (error) {
      setIsLoading(false);
      setError(error.message);
      emailRef.current.focus();
    }

    // reset the inputs
    dispatchEmail({ type: "INPUT_RESET" });
    dispatchFirstname({ type: "INPUT_RESET" });
    dispatchLastname({ type: "INPUT_RESET" });

    // first the confirmPass must be reset otherwise a bug will happen.
    dispatchConfirmPassword({ type: "INPUT_RESET" });
    dispatchPassword({ type: "INPUT_RESET" });
  };

  const googleSuccessHandler = async (response) => {
    // localStorage.setItem("user", JSON.stringify(response.profileObj));
    try {
      const res = await fetch(`${BaseURL}/auth/google-login`, {
        method: "POST",
        body: JSON.stringify({ tokenId: response.tokenId }),
        headers: {
          "Content-type": "application/json",
        },
      });

      if (!res.ok) {
        const { error: errorMessage } = await res.json();
        throw new Error(errorMessage);
      }

      const data = await res.json();
      dispatch(userActions.login(data));
      localStorage.setItem("user-token", data.token);
      navigate("/", { replace: true });
    } catch (error) {
      setError(error.message);
    }

    // const { name, googleId, imageUrl } = response.profileObj;

    // const doc = {
    //   _id: googleId,
    //   _type: "user",
    //   userName: name,
    //   image: imageUrl,
    // };

    // client.createIfNotExists(doc).then(() => {
    //   navigate("/", { replace: true });
    // });
  };

  const googleFailureHandler = (response) => {
    setError("Failed to signup with google please try again.");
  };

  return (
    <FormCard >
      <div className=" mb-20 flex justify-center self-center ">
        <img src={logo} className="w-60" alt="logo" />
      </div>

      {errorState && (
        <div className=" flex items-center justify-center bg-red-200 p-4 text-[1.6rem]">
          {errorState}
        </div>
      )}

      {isLoading && (
        <div className="mt-4 flex items-center justify-center p-4 text-[1.6rem] ">
          <span className="mr-2">
            <ImSpinner className="animate-spin" />
          </span>{" "}
          Loading...
        </div>
      )}

      <form
        onSubmit={formSubmitHandler}
        className={`flex h-fit w-full flex-col text-[1.6rem]`}
      >
        <div>
          <FormRadioInput
            label="Are you a researcher?"
            options={["yes", "no"]}
            name="user-type"
            onChange={radionInputChangeHandler}
          />
          <FormInput
            required={true}
            ref={emailRef}
            value={email.value}
            onChange={emailHandler}
            htmlFor="email"
            label="email address"
            inputType="text"
            isValid={email.isValid}
            isTouched={email.isTouched}
            error={email.error}
            showError={email.showError}
          />
          <div className="md:flex md:gap-4">
            <FormInput
              required={true}
              value={firstname.value}
              onChange={firstnameHandler}
              className="w-full"
              htmlFor="firstname"
              label="first name"
              inputType="text"
              isValid={firstname.isValid}
              isTouched={firstname.isTouched}
              error={firstname.error}
              showError={firstname.showError}
            />
            <FormInput
              required={true}
              value={lastname.value}
              onChange={lastnameHandler}
              className="w-full"
              htmlFor="lastname"
              label="last name"
              inputType="text"
              isValid={lastname.isValid}
              isTouched={lastname.isTouched}
              error={lastname.error}
              showError={lastname.showError}
            />
          </div>
          <FormInput
            htmlFor="password"
            value={password.value}
            onChange={passwordHandler}
            required={true}
            label="password"
            inputType="password"
            className=""
            requirementMessage="password must be at least 7 characters"
            isValid={password.isValid}
            isTouched={password.isTouched}
            error={password.error}
            showError={password.showError}
          />
          <FormInput
            value={confirmPassword.value}
            onChange={confirmPasswordHandler}
            htmlFor="confirmPassword"
            label="Confirm your password"
            inputType="password"
            className=""
            isValid={confirmPassword.isValid}
            isTouched={confirmPassword.isTouched}
            error={confirmPassword.error}
            showError={confirmPassword.showError}
          />
        </div>
        <div className="flex flex-col justify-between md:flex-row md:items-end">
          <Button
            className="mb-4 bg-blue-700 hover:bg-blue-600 md:mb-0 md:mr-8 md:w-1/3 text-white"
            buttonText="Sign up"
            type="submit"
          />
          <div className="mt-4 text-[1.4rem] text-gray-600 md:mt-0">
            Already have an account?{" "}
            <Link
              className="py-4 font-bold text-blue-600 hover:underline"
              to="/account/Login"
            >
              Sign in
            </Link>
          </div>
        </div>
      </form>

      {/* OR */}
      <div className="my-8 flex h-12 items-center text-[1.6rem]">
        <span className="block h-0.5 w-2/5 bg-gray-300"></span>
        <span className="block w-1/5 text-center">OR</span>
        <span className="block h-0.5 w-2/5 bg-gray-300"></span>
      </div>

      {/* Google auth */}
      <div className="">
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_API_TOKEN}
          buttonText={"Sign up with google"}
          onSuccess={googleSuccessHandler}
          onFailure={googleFailureHandler}
          cookiePolicy="single_host_origin"
          render={(renderProps) => {
            return (
              <Button
                onClick={renderProps.onClick}
                onDisable={renderProps.disabled}
                className="relative mx-auto flex items-center justify-center w-2/3 bg-blue-700 text-white hover:bg-blue-600 "
                buttonText="Sign up with Google"
              >
                <span className="rounded-lg bg-white mr-3">
                  <FcGoogle className="h-12 w-12 p-1" />
                </span>
              </Button>
            );
          }}
        />
      </div>
    </FormCard>
  );
}

export default SignupForm;
