import React,{useState, useRef} from 'react';
import { useEffect } from 'react';

const Captions = (props) =>{

    const [curCaption, setCurCaption] = useState('');
    const [allCaptions, setAllCaptions] = useState([]);
    const captionRef = useRef();

    
    console.log(props.selectedImages)
    const length = props.selectedImages.length;

    const focusOnCaption = () =>{
        captionRef.current.focus();
    }

    const pushToCaptionArray = () => {
        setAllCaptions((prevCaption)=>[...prevCaption, curCaption]); 
        setCurCaption('')
    }

    const confirmCaptions = () =>{
    props.setAllCaptions(allCaptions);
    console.log(allCaptions)
    }

    const resetCaptions = () =>{
        setAllCaptions([])
        setCurCaption('')
    }


    return(
        <div data-aos="fade-up" className="flex flex-col justify-between items-center">
        <div>
            <h1 className="text-lg">Add caption in each image respectively then click Confirm</h1>
        </div>

        <div className="snap-x">
            <div className="snap-ceneter"> 
            {
                props.selectedImages?.map((img, i)=>(
                    <div>
                    <img src={img?.path} className="h-[50rem] p-8 object-fill"/>
                    <textarea
                    className="ml-8 h-1/2 w-3/4 rounded border-2 border-black p-6 text-[1.4rem]"
                    onChange={(e)=>setCurCaption(e.target.value)}
                    />
                    <button 
                    className="flex flex-row ml-8 bg-transparent hover:bg-black text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                    onClick={pushToCaptionArray}
                    >Add Caption
                    </button>
                    </div>
                ))
            }
            </div>
            </div>

                    <button 
                    className="mt-6 ml-8 bg-transparent hover:bg-black text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                    onClick={confirmCaptions}
                    >Confirm
                    </button>

                    <button 
                    className="ml-8 bg-transparent hover:bg-black text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded"
                    onClick={resetCaptions}
                    >Reset All Captions (Delete them)
                    </button>

        </div>
    )
}


export default Captions;