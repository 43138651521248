import React from "react";
import { Link } from "react-router-dom";
import { RiChat1Line } from "react-icons/ri";
import { DateAgo } from "../../../utils/DateAgo";
import { categories } from "../../../utils/data";

function QuestionComponent(props) {
  let date = Date.parse(props.question.createdAt);
  const catagory = categories.filter((item) =>
    item.communityId.includes(props.question.communityId[0])
  );

  console.log(props.question);
  return (
    <Link to={`/home/questions/${props.question._id}`}>
      <div className="w-[400px] cursor-pointer rounded-lg bg-white p-8 shadow-sm hover:shadow-md">
        {catagory[0] && (
          <h2 className="my-3 w-fit rounded-md bg-cyan-200 py-[1px] px-3 text-center text-[13px] text-gray-600">
            {catagory[0].name}
          </h2>
        )}
        <p className="flex text-[16px]">
          <RiChat1Line className="mt-1 mr-2" color="#f0da9e" size="21" />
          {props.question.question}
        </p>
        <h2 className="mt-4 text-[12px] text-gray-600">{DateAgo(date)}</h2>
      </div>
    </Link>
  );
}

export default QuestionComponent;
