import React from "react";
import { Link } from "react-router-dom";

//Components
import { SearchBar } from "./UI/SearchBar";
import { Profile } from "./UI/Profile";

//Icons
import { IoMdAdd } from "react-icons/io";

const Navbar = (props) => {
  return (
    <div
      className={`flex ${
        props.user?.isResearcher ? "gap-4" : ""
      } bg-white md:gap-8 p-5`}
    >
      {/* <SearchBar /> */}

      {/* user profile & add new video icon */}
      {props.user && (
        <div className="flex md:gap-8 justify-end  ml-auto mr-10">
          <Profile
            image={props.user.image}
            name={props.user.firstname}
            className={"hidden w-4/2 md:flex"}
            userId={props.user.id}
          />
          {props.user?.isResearcher && (
            <Link
              to="/home/select-upload"
              className="flex items-center justify-center gap-2 rounded-lg bg-blue-500 px-[.5em] text-[2rem] text-gray-200 hover:bg-blue-700 w-3/6"
            >
              <IoMdAdd className="" />
              New
            </Link>
          )}
        </div>
      )}
    </div>
  );
};

export default Navbar;
