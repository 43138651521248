import React from "react";
import Button from "../../UI/Button";

const ProgressStepsActions = ({
  handleClick,
  currentStep,
  steps,
  isNextEnable,
}) => {
  return (
    <div
      className={`mt-20 flex ${
        currentStep === 1 ? "justify-end" : "justify-between"
      }`}
    >
      <Button
        onClick={handleClick}
        buttonText="Back"
        className={`border-2 border-gray-400  text-gray-400 hover:border-black hover:text-black  ${
          currentStep === 1 ? "hidden" : ""
        }`}
      />
      <Button
        onClick={() => handleClick("next")}
        buttonText={`${currentStep === steps.length ? "Share" : "Next"}`}
        className="border-2 border-transparent bg-blue-600  text-white hover:bg-blue-700"
        disabled={!isNextEnable}
      />
    </div>
  );
};

export default ProgressStepsActions;
