import { Box, Typography, styled, Card } from "@mui/material";

export const CardWrapper = styled(Box)`
  display: flex;
  box-shadow: rgba(240, 46, 170, 0.4) -5px 5px, rgba(240, 46, 170, 0.3) -10px 10px, rgba(240, 46, 170, 0.2) -15px 15px, rgba(240, 46, 170, 0.1) -20px 20px, rgba(240, 46, 170, 0.05) -25px 25px;
`;

export const CardContainer = styled(Card)`
  max-width: 350px;
  @media (max-width:650px){
    max-width: 93%;
  }
`;

export const CardMainText = styled(Typography)`
  margin-top: 0.5em;
  font-size: 4.5rem;
  font-family: "Inter", sans-serif;
  color: rgba(255, 255, 255, 1);
  font-weight: 700;
  text-align: center;
  text-transform: capitalize;
  margin: 4em 0;
  margin-bottom: 2em;
  letter-spacing: 2px;
  @media (max-width:650px){
    font-size: 3.5rem;
  }
`;

export const HeaderBox = styled(Box)`
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1em;
  margin-left: auto;
  margin-right: auto;
`;

export const HeaderNameText = styled(Typography)`
  text-align: center;
  margin-top: 1em;
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: uppercase;
`;

export const ProfessionText = styled(Typography)` 
 margin: .4em auto;
 font-size: 1.5rem;
 font-weight: bold;
 text-transform: uppercase;
 text-align: center;
 color:rgb(49 46 129);
`

export const QuoteIcon =styled(Box)` 
 text-align: center;
 width: 100%;
`

export const CardContentText = styled(Typography)`
  font-size: 1.5rem;
  font-family: "Poppins", sans-serif;
  text-align: center;
`;
