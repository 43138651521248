import styled from "@emotion/styled";

export const HomeWrapper = styled.div`
  width: 100%;
  max-width: 90%;
  margin: 0 auto;
  color: blue;
  @media (max-width:650px){
    position: relative;
    max-width: 100%;
    top:0;
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin: 3em auto;
  @media (max-width:650px){
    width: 100%;
    position: absolute;
    flex-direction: column;
    min-height: 100vh;
    top: 0;
    left: 0;
    z-index: 999;
    align-items:center;
    background-color: rgb(28 25 23);
    margin-top:0;
    margin-bottom: 0;
    transition: transform .5s;
    padding-top: 12em;
  }
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
`;

export const LogoText = styled.p`
  margin-top: 2.5em;
`;

export const NavigationBox = styled.div`
  margin-left: auto;
  margin-right: 6em;
  margin-top: 1em;
  width: 60%;
  @media (max-width:650px){
    margin-left:auto;
    margin-right: auto;
    margin-right: 0;
    margin-top: 1em;
    width: 100%;
  }
`;

export const ListContainer = styled.ul`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width:650px){
    flex-direction: column;
  }
`;

export const ListItem = styled.li`
  list-style: none;
  font-weight: 700;
  letter-spacing: 2px;
  @media (max-width:650px){
    margin: 2em 0;
  }
`;

export const Link = styled.a`
  text-decoration: none;
  color: rgba(255, 255, 255, 1);
  :hover {
    color: lightblue;
    cursor: pointer;
  }
`;

export const ContactButton = styled.button`
  border: 1px solid rgba(197, 132, 245, 1);
  background-color: #0e0e10;
  color: #ffffff;
  padding: 0.5em;
  border-radius: 5px;
  letter-spacing: 2px;
  :hover {
    cursor: pointer;
  }
`;
