// react imports
import React, { useRef, useState } from "react";
import { useNavigate, Link, redirect } from "react-router-dom";
import { useDispatch } from "react-redux";

// custom hooks
import useInput from "../../hooks/useInput-hook";
import { BaseURL } from "../../utils/config";

// login-specific imports
import GoogleLogin from "react-google-login";
import { client } from "../../client";

// components
import Button from "../UI/Button";
import FormInput from "../UI/FormInput";
import FormCard from "../UI/FormCard";

// icons
import logo from "../../assets/logo.png";
import { FcGoogle } from "react-icons/fc";
import { ImSpinner } from "react-icons/im";
import { userActions } from "../../store/userSlice";

const MINIMUM_PASS_LENGTH = 1;

const validateEmail = (email) => {
  //const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const regex = /^[a-zA-Z0-9.-1.]+@[a-zA-Z0-9.]+\.[A-Za-z]+$/;
  return regex.test(email).valueOf();
};

const validatePassword = (password) => {
  return password.trim().length >= MINIMUM_PASS_LENGTH;
};

const generateEmailError = (email) => {
  const errorMessage =
    email.trim().length === 0
      ? "Please enter your email address."
      : "That's an invalid email.";
  return errorMessage;
};

const generatePasswordError = (password) => {
  if (password.trim().length === 0) {
    return "Please enter your password.";
  }
};


function LoginForm(props) {
  // redux-toolkit
  const dispatch = useDispatch();

  const emailRef = useRef();

  const navigate = useNavigate();

  // form state
  const [isLoading, setIsLoading] = useState(false);
  const [errorState, setError] = useState("");

  const redirectToLocation = window.location.toString().split("redirect=")[1];


  // input states
  const { inputState: email, dispatchInput: dispatchEmail } = useInput(
    validateEmail,
    generateEmailError
  );

  const { inputState: password, dispatchInput: dispatchPassword } = useInput(
    validatePassword,
    generatePasswordError
  );

  // handler functions
  const emailHandler = (e) => {
    dispatchEmail({ type: "USER_INPUT", value: e.target.value });
  };

  const passwordHandler = (e) => {
    dispatchPassword({ type: "USER_INPUT", value: e.target.value });
  };

  const formSubmitHandler = async (e) => {
    e.preventDefault();

    // show error messages for invalid inputs
    if (!email.isValid) {
      dispatchEmail({ type: "INVALID_INPUT" });
    }
    if (!password.isValid) {
      dispatchPassword({ type: "INVALID_INPUT" });
    }

    if (!email.isValid || !password.isValid) return;

    // if inputs were valid send http request to server

    try {
     
      setIsLoading(true);
      const res = await fetch(`${BaseURL}/auth/login`, {
        method: "POST",
        body: JSON.stringify({
          email: email.value.toLowerCase(),
          password: password.value,
        }),
        headers: {
          "Content-type": "application/json",
        },
      });
      setIsLoading(false);

      if (!res.ok) {
        const { error: errorMessage } = await res.json();
        throw new Error(errorMessage);
      }

      // res ok, then store the token, the data has refresh and access token
      const data = await res.json();
      dispatch(userActions.login(data));
      localStorage.setItem("user-token", data.token);
      
      if(redirectToLocation === "toHome"){
        navigate("/home")
      }
      else if(redirectToLocation === "toCreate"){
        navigate("/home/select-upload")
      }
      // navigate("/", {
      //   replace: true,
      // });
    } catch (error) {
      setIsLoading(false);
      setError(error.message);
      dispatchPassword({ type: "INPUT_RESET" });
    }

  };

  const googleSuccessHandler = async (response) => {
    try {
      const res = await fetch(`${BaseURL}/auth/google-login`, {
        method: "POST",
        body: JSON.stringify({ tokenId: response.tokenId }),
        headers: {
          "Content-type": "application/json",
        },
      });

      if (!res.ok) {
        const { error: errorMessage } = await res.json();
        throw new Error(errorMessage);
      }

      const data = await res.json();

      dispatch(userActions.login(data));
      localStorage.setItem("user-token", data.token);

      if(redirectToLocation === "toHome"){
        navigate("/home",{replace:true})
      }
      else if(redirectToLocation === "toCreate"){
        navigate("/home/select-upload",{replace:true})
      }
      
    } catch (error) {
      setError(error.message);
    }

    // const { name, googleId, imageUrl } = response.profileObj;

    // const doc = {
    //   _id: googleId,
    //   _type: "user",
    //   userName: name,
    //   image: imageUrl,
    // };

    // client.createIfNotExists(doc).then(() => {
    //   navigate("/", { replace: true });
    // });
  };

  const googleFailureHandler = (response) => {
    setError("Failed to login with google please try again.");
  };

  return (
    <FormCard>
      <div className="mb-12 flex justify-center self-center ">
        <img src={logo} className="w-60" alt="logo" />
      </div>

      {errorState && (
        <div className="mb-12 flex items-center justify-center bg-red-200 p-4 text-[1.6rem]">
          {errorState}
        </div>
      )}
      {isLoading && (
        <div className="mt-4 flex items-center justify-center p-4 text-[1.6rem] ">
          <span className="mr-2">
            <ImSpinner className="animate-spin" />
          </span>{" "}
          Loading...
        </div>
      )}

      <form
        onSubmit={formSubmitHandler}
        className={`flex h-340  w-full flex-col text-[1.6rem] justify-center  `}
      >
        <div>
          <FormInput
            ref={emailRef}
            value={email.value}
            onChange={emailHandler}
            htmlFor="email"
            label="email address"
            inputType="text"
            isTouched={email.isTouched}
            error={email.error}
            showError={email.showError}
          />

          <FormInput
            htmlFor="password"
            value={password.value}
            onChange={passwordHandler}
            label="password"
            inputType="password"
            className=""
            isTouched={password.isTouched}
            error={password.error}
            showError={password.showError}
          />
        </div>

        <div className="flex  flex-col items-baseline justify-between md:flex-row md:items-baseline">
          <Button
            className="mb-4 bg-blue-700 text-white hover:bg-blue-600 md:mb-0 md:mr-8 md:w-1/3"
            buttonText="Login"
            type="submit"
          />

          <div>
          <div className="mt-4 text-[1.4rem] text-gray-600 md:mt-0">
            New User?{" "}
            <Link
              className="py-4 font-bold text-blue-700 hover:underline"
              to="/account/signup"
            >
              Create an account
            </Link>
          </div>
          <br/>
          <div className="mt-4 text-[1.4rem] text-gray-600 md:mt-0">
            Forgot password?{" "}
            <Link
              className="py-4 font-bold text-blue-700 hover:underline"
              to="/email-verification"
            >
              Click here to reset
            </Link>
          </div>
          </div>



        </div>
      </form>

      {/* OR */}
      <div className="my-8 flex h-12 items-center text-[1.6rem]">
        <span className="block h-0.5 w-2/5 bg-gray-300"></span>
        <span className="block w-1/5 text-center">OR</span>
        <span className="block h-0.5 w-2/5 bg-gray-300"></span>
      </div>

      {/* Google auth */}
      <div className="">
        <GoogleLogin
          clientId={process.env.REACT_APP_GOOGLE_API_TOKEN}
          buttonText={"Login with google"}
          onSuccess={googleSuccessHandler}
          onFailure={googleFailureHandler}
          cookiePolicy="single_host_origin"
          render={(renderProps) => {
            return (
              <Button
                onClick={renderProps.onClick}
                onDisable={renderProps.disabled}
                className="relative mx-auto  flex w-2/3 items-center justify-center bg-blue-700 text-white hover:bg-blue-600 "
                buttonText="Login With Google"
              >
                <span className=" mr-5 rounded-md bg-white ">
                  <FcGoogle className="h-12 w-12 p-1" />
                </span>
              </Button>
            );
          }}
        />
      </div>
    </FormCard>
  );
}

export default LoginForm;
