import React, { useRef, useState, useEffect } from "react";
import { AiFillMail } from "react-icons/ai";
import emailjs from "@emailjs/browser";
import Aos from "aos";
import { useNavigate } from "react-router-dom";

const Result = () => {
  return (
    <>
      <h1 className="mb-2 text-center text-4xl font-bold">Thank You</h1>
      <p className="text-md text-center font-sans font-medium tracking-wide text-gray-700">
        Your message has been successfully sent. We will contact you soon!
      </p>
    </>
  );
};

const ContactUs = () => {
  const navigate = useNavigate()
  const form = useRef();
  const [result, setResult] = useState(false);

  useEffect(() => {
    Aos.init({ duration: 1000 });
  });

  const sendEmail = async (e) => {
    e.preventDefault();
    try {
      const resp = await emailjs.sendForm(
        "service_nley0i6",
        "template_prhozb",
        form.current,
        "user_qnXFUCF96SGbv1guVd2VN"
      );
      const data = await resp.text;
      console.log(data)
    } catch (err) {
      console.log(err);
    } finally {
      setResult(true);
      
      setTimeout(() => {
        setResult(false);
        navigate("/")
      }, 4000);
      
      e.target.reset();
    }
  };

  useEffect(() => {
    Aos.init({ duration: 1000 });
  });

  return (
    <div
      className={`${
        result ? "flex h-screen items-center justify-center bg-white" : ""
      }`}
    >
      {!result ? (
        <div className=" flex h-screen w-full flex-1 flex-col gap-6 border-2 bg-white  bg-no-repeat bg-blend-multiply lg:pl-5  ">
          <form
            data-aos="fade-in"
            ref={form}
            onSubmit={sendEmail}
            className="mx-auto mt-40 h-[400px] w-[40%]  max-w-6xl rounded-md border-2 border-gray-200 bg-white  p-6 shadow-lg "
          >
            <h1 className="mb-12 flex items-center justify-center text-4xl font-bold">
              {<AiFillMail className="mr-4" style={{ color: "gray" }} />} Send
              Mail{" "}
            </h1>

            <div className="mt-5 flex w-full flex-1 flex-col gap-6 lg:pl-5 lg:pr-5">
              <input
                type="text"
                placeholder="Enter your name"
                name="from_name"
                className=" font-semi-bold mt-10 w-full  rounded-md border-2 border-gray-200 p-4 text-xl tracking-wide placeholder-black hover:outline-1 focus:outline-1 focus:outline-gray-300"
              />

              <input
                type="text"
                placeholder="Enter your email address"
                name="email"
                className="font-semi-bold mt-10 w-full   rounded-md border-2 border-gray-200 p-4 text-xl tracking-wide placeholder-black hover:outline-1 focus:outline-1 focus:outline-gray-300"
              />

              <textarea
                type="text"
                placeholder="Message:"
                name="message"
                className="font-semi-bold mt-10 w-full  rounded-md border-2 border-gray-200 p-4 text-xl tracking-wide placeholder-black hover:outline-1 focus:outline-1 focus:outline-gray-300"
                required
              />

              <button
                className="mx-auto mt-10 block w-1/2 cursor-pointer rounded-lg bg-black from-neutral-300 p-3 text-xl tracking-wide  text-white outline-none transition-colors duration-1000 ease-in hover:bg-gray-700 hover:font-bold hover:text-white hover:shadow-md"
                type="submit"
                value="Send"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      ) : (
        <div data-aos="fade-in">
          <p className="text-2xl">{result ? Result() : ""}</p>
        </div>
      )}
    </div>
  );
};

export default ContactUs;