import { styled, Box, Typography } from "@mui/material";

export const ResearchWrapper = styled(Box)`
  margin-top: 5em;
  background: linear-gradient(0deg, #d2a010, #d2a010),
    linear-gradient(0deg, rgba(210, 160, 16, 0.7), rgba(210, 160, 16, 0.7));
  padding: 1em;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
`;

export const ResearchContainer = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  width: 90%;
  margin: 2em auto;
  padding: 1em;
  font-family: "Inter", sans-serif;
  @media (max-width:650px){
    width: 100%;
    flex-direction: column;
  }
`;

export const ResearchMainText = styled(Typography)`
  margin-top: 0.5em;
  font-size: 4rem;
  font-family: "Inter", sans-serif;
  color: rgba(255, 255, 255, 1);
  font-weight: 700;
  text-align: center;
  text-transform: capitalize;
  color: rgba(0, 0, 0, 1);
  @media (max-width:650px){
   font-size: 3rem;
  }
`;

export const ResearchItem = styled(Box)`
  width: 32%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width:650px){
    width: 100%;
    margin: 2em 0;
  }
`;

export const ResearchText = styled(Typography)`
  font-size: 2rem;
  margin-top: 2em;
  font-family: "Poppins", sans-serif;
  color: rgba(255, 255, 255, 1);
  text-align: center;
  letter-spacing: 2px;
  color: rgba(0, 0, 0, 1);
  font-weight: 500;
  @media (max-width:650px){
    margin-top: 1em;
  }
`;
