import React, { useState, useRef } from "react";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import emailjs from "@emailjs/browser";
import TimePicker from "react-time-picker";
// import { DateTimePicker } from '@mui/lab';
// import DateTimePickerComponent from '@mui/lab'

// import "react-modern-calendar-datepicker/lib/DatePicker.css";
// import DatePicker from 'react-modern-calendar-datepicker';

const Booking = () => {
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState("");
  const [result, setResult] = useState(false);
  const [email, setEmail] = useState("");
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_5xyellj",
        "template_pgrhozb",
        form.current,
        "user_qnXFUCF96SGbv1guVd2VN"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    setResult(true);
  };

  // const minTime = new Date("01/01/2022 10:00 AM");
  //const maxTime = new Date("01/02/2022 10:00 PM");

  /*
        <DateTimePickerComponent
        value={date}
        onChange={setDate}
        placeholder='Click on Calendar to choose time'
        >
            </DateTimePickerComponent>
      */

  return (
    <div className="flex flex-1 flex-col gap-6 lg:pl-5 mt-5 w-full ">
      <p className="text-center">
        Book an appointment with one of the editors to review your video and
        edit it
      </p>
      <p className="text-center font-bold">this feature is under development</p>

      <Calendar onChange={setDate} value={date} className="w-full" />

      <small>appointments are open from 12pm to 9pm</small>
      <TimePicker onChange={setTime} value={time} />

      <form
        ref={form}
        onSubmit={sendEmail}
        className="flex flex-1 flex-col gap-6 lg:pl-5 mt-5 w-full "
      >
        <input
          type="text"
          placeholder="Enter your name"
          name="from_name"
          className="outline-none text-base sm:text-lg border-b-2 border-gray-200 p-2"
        />

        <input
          type="text"
          placeholder="Enter your email address"
          name="email"
          className="outline-none text-base sm:text-lg border-b-2 border-gray-200 p-2"
        />

        <textarea
          type="text"
          name="message"
          placeholder="Enter a message"
          className="outline-none text-base sm:text-lg border-b-2 border-gray-200 p-2"
          required
        />

        <textarea
          type="text"
          name="appointment"
          value={"Date: " + date + "\nTime:" + time}
          onChange={(e) => e.target.value}
          className="outline-none text-base sm:text-lg border-b-2 border-gray-200 p-2"
          required
        />

        <button
          className="bg-black text-white mt-3 p-3 rounded-full cursor-pointer outline-none hover:shadow-md transition-all duration-500 ease-in-out"
          type="submit"
          value="Send"
        >
          Confirm Booking
        </button>
      </form>
    </div>
  );
};

export default Booking;
