import React from "react";
import UserAvtar from "../../UI/UserAvtar";

function AnswerComponentCard({ answerData }) {
  return (
    <>
      {/* {comments.map((commentItem, ) => ( */}
      <div className="my-8 flex items-start rounded-md border-[1.4px] border-gray-200 p-4">
        {/* user image */}
        <UserAvtar
          userName={answerData.name}
          className="h-[3.1rem] w-[3.1rem] items-start text-lg"
        />
        <div className="ml-4">
          {/* Researcher name */}
          <h2 className="mt-1 text-2xl font-medium">{answerData.name}</h2>
          {/* Researcher title */}
          {/* comment */}
          <p className="mt-1 text-xl">{answerData.desc}</p>
        </div>
      </div>
      {/* ))} */}
    </>
  );
}

export default AnswerComponentCard;
