import React, { useEffect } from "react";
import Aos from "aos";
import {Link, NavLink }from "react-router-dom"

import {
  CommunityContainer,
  CommunityText,
  HeroContainer,
  HeroMainText,
  LearnContainer,
  LearnText,
  LearnCommunityContainer,
  JoinButton,
  TryButton
} from "./HeroStyles";
import Typical from "react-typical";

function Hero() {
  const community_steps = [
    "RESEARCH RESULT",
    4000,
    "RESEARCH PROFILE",
    3000,
    "RESEARCH PROCEDURE",
    3000,
  ];

  const learn_steps = ["ANYTIME", 4000, "ANYWHERE", 3000];

  useEffect(() => {
    Aos.init({ duration: 1000, easing: "ease-in-out" });
  });

  return (
    <HeroContainer data-aos="fade-in">
      <HeroMainText variant="h1" data-aos="slide-right">
        Explore Latest Research
      </HeroMainText>
      <LearnCommunityContainer>
        
        <LearnContainer>
          <LearnText component="h5" variant="h5">
            <div className="flex-all">
              LEARN{" "}
              <Typical
                className="typical"
                steps={learn_steps}
                loop={Infinity}
                wrapper="h4"
              />
            </div>
            {/* <ExpertText  component="h5" variant="h5">From The Experts</ExpertText> */}
            FROM ThE EXPERTS
          </LearnText>
          {/* <JoinButton variant="standard"></JoinButton> */}
          <Link 
          to="/account/login?redirect=toHome"
          className="text-3xl font-sans mt-2 text-white bg-gradient-to-r from-pink-400 via-pink-500 to-purple-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-pink-300 dark:focus:ring-pink-800 font-medium rounded-lg px-20 py-2.5 text-center mr-2 mb-2">
            Join For Free
          </Link>

        </LearnContainer>


        <CommunityContainer>
          <CommunityText component="h5" variant="h5">
            Communicate With Video Your
            <Typical
              className="typical"
              steps={community_steps}
              loop={Infinity}
              wrapper="h4"
            />
          </CommunityText>
          <Link 
          to="/account/login?redirect=toCreate"
          className="text-3xl font-sans mt-2 text-white bg-gradient-to-r from-pink-400 via-pink-500 to-purple-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-pink-300 dark:focus:ring-pink-800 font-medium rounded-lg px-20 py-2.5 text-center mr-2 mb-2">
            Try For Free
          </Link>
          
        </CommunityContainer>


      </LearnCommunityContainer>

    </HeroContainer>
  );
}

export default Hero;
