import React, { useState, useEffect } from "react";
import Button from "../../UI/Button";
import AnswerComponentCard from "./AnswerComponentCard";
import { BaseURL } from "../../../utils/config";

function AnswerComponent({ questionId, userId }) {
  const [allAnswerData, setAllAnswerData] = useState([]);
  const [inputCurrAnswer, setInputCurrAnswer] = useState("");

  const fetchAnswerData = () => {
    fetch(`${BaseURL}/answer/get-answers?questionId=${questionId}`)
      .then((res) => res.json())
      .then((data) => {
        setAllAnswerData(data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchAnswerData();
  }, []);

  const onClickAnwerHandler = () => {
    fetch(`${BaseURL}/answer/add-answer`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: userId,
        questionId: questionId,
        desc: inputCurrAnswer,
      }),
    })
      .then(setInputCurrAnswer(""))
      .then(fetchAnswerData);
  };

  return (
    <div className="mx-4">
      <h1 className="mt-8 text-xl text-gray-800">New Answer</h1>
      <input
        placeholder="Answer it here.."
        className="my-2 w-full rounded border-[1.4px] border-gray-200 p-6 text-[1.4rem]"
        value={inputCurrAnswer || ""}
        onChange={(e) => setInputCurrAnswer(e.target.value)}
      />
      <Button
        onClick={onClickAnwerHandler}
        className="mt-3 cursor-pointer rounded-full bg-gray-700 p-3 text-white outline-none transition-all duration-500 ease-in-out hover:bg-gray-800 hover:shadow-lg"
      >
        Comment
      </Button>
      {allAnswerData.map((answerData, index) => (
        <AnswerComponentCard answerData={answerData} key={index} />
      ))}
    </div>
  );
}

export default AnswerComponent;
