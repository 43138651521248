import React, { useState, useRef, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { AiFillCheckCircle } from "react-icons/ai";
import axios from "axios";
import { storage } from "../../../firebase/firebase.js";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import jwt_decode from "jwt-decode";
import { BaseURL } from "../../../utils/config";

const UploadAssets = (props) => {
  const token = localStorage.getItem("user-token");

  const [uploadedImages, setUploadedImages] = useState([]);
  const [curKeyword, setCurKeyword] = useState("");

  const formData = new FormData();

  const uploadHandler = (e) => {
    setUploadedImages(e.target.files);
  };

  const confirmCloudinaryUpload = () => {
    const url = "https://api.cloudinary.com/v1_1/ddbkp0c8b/image/upload";
    const formData = new FormData();

    for (let i = 0; i < uploadedImages.length; i++) {
      let file = uploadedImages[i];
      formData.append("file", file);
      formData.append("upload_preset", "reactupload");

      fetch(url, {
        method: "POST",
        body: formData,
      })
        .then((res) => {
          return res.json();
        })
        .then((data) =>
          props.setUploadedImagesURL((prev) => [...prev, data.url])
        )
        .then(() => console.log(props.uploadedImagesURL));
    }
  };

  const addImagesToDB = async () => {
    const decoded = jwt_decode(token || "") || null;
    const id = decoded.userId;
    for (let i = 0; i < props.uploadedImagesURL.length; i++) {
      await fetch(`${BaseURL}/image/add`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: id,
          imageUrl: props.uploadedImagesURL[i].toString(),
          keyword: props.imageKeywords[i],
        }),
      })
        .then(() => {
          console.log(`image ${i} added to DB`);
        })
        .catch(() => {
          console.log(`image ${i} FAILED TO BE added to DB`);
        });
    }
  };

  const addKeywordToImage = (e) => {
    props.setImageKeywords((prev) => [...prev, curKeyword]);
  };

  const reset = () => {
    props.setUploadedImagesURL([]);
  };

  return (
    <div data-aos="fade-up" className=" py-10 px-4 flex flex-col gap-10 border-2 w-full">
      <div className="flex flex-col gap-10">
        <input
        className="text-2xl text-slate-800"
          type="file"
          accept="jpg, png, jpeg, gif"
          multiple
          onChange={uploadHandler}
        />
        <h1 className="text-4xl text-slate-800">
          Upload All Assets You want then Click On Confirm Assets
        </h1>

        <button
          className="rounded text-2xl uppercase border py-3 px-4 w-1/4 bg-transparent bg-blue-700 hover:bg-blue-600 font-semibold text-neutral-100 hover:border-transparent hover:text-white"
          onClick={confirmCloudinaryUpload}
        >
          Upload
        </button>

        <button
          className=" rounded-md text-2xl uppercase border py-3 px-4 w-1/4 bg-transparent bg-blue-700 hover:bg-blue-600 font-semibold text-neutral-100 hover:border-transparent hover:text-white"
          onClick={addImagesToDB}
        >
          Confirm Assets
        </button>

        <button
          className="rounded-md  text-2xl uppercase border py-3 px-4 w-1/4 bg-transparent bg-blue-700 hover:bg-blue-600 font-semibold text-neutral-100 hover:border-transparent hover:text-white"
          onClick={reset}
        >
          Reset
        </button>
      </div>

      <h1 className="bold text-2xl">Add keyword for each image</h1>

      <div className="flex flex-wrap gap-10">
        {props.uploadedImagesURL.map((img, index) => (
          <div className="relative" key={index}>
            <img className="h-[25rem] object-fill" src={img} key={index} />
            <input
              className="m-2 border-gray-200 p-2 outline outline-1 outline-offset-2"
              type="text"
              placeholder="keyword"
              onChange={(e) => setCurKeyword(e.target.value)}
            />
            <button
              className="rounded border border-blue-500 bg-transparent px-10 py-3 font-semibold text-blue-700 hover:border-transparent hover:bg-black hover:text-white"
              onClick={addKeywordToImage}
            >
              Add
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UploadAssets;
