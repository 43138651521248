import React,{useState} from 'react'
import { userQuery } from '../utils/data';
import { client } from '../client';
import { NavLink, useNavigate } from 'react-router-dom';
import { fetchUser } from '../utils/fetchUser';

const Settings = () => {

    const [name, setName] = useState('')
    const [profilePicture, setProfilePicture] = useState('')
    const [email, setEmail] = useState('')
    const [education, setEducation] = useState('')
    const [scholar, setScholar] = useState('')
    const [bio, setBio] = useState('')

    const navigate = useNavigate();
    const user = localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : localStorage.clear();
    //const fetched = userQuery(user.id)

    
    
    const saveProfile = () => {
        
        if(name && email && profilePicture && education && scholar && bio)
        {
            const doc = {
                _type:'user',
                userName:name,
                image:profilePicture,
                email:email,
                _id:user.googleId,
                education:education,
                scholar:scholar,
                bio:bio,
            }
            client.createOrReplace(doc)
            .then(()=>{
                navigate('/')
            })
            
        }else {
            window.alert("fill all fields with values or N/A");
        }
    }
    

    return (
        <div className="flex flex-1 flex-col gap-6 lg:pl-5 mt-5 w-full">

            <input 
            type="text"
            placeholder='Enter your name'
            className="outline-none text-base sm:text-lg border-b-2 border-gray-200 p-2"
            value={name}
            onChange={(e) => setName(e.target.value)}
            />      

            <input 
            type="text"
            placeholder='Enter URL for profile picture'
            className="outline-none text-base sm:text-lg border-b-2 border-gray-200 p-2"
            value={profilePicture}
            onChange={(e) => setProfilePicture(e.target.value)}
            />

            <input 
            type="text"
            placeholder='Enter your email address'
            className="outline-none text-base sm:text-lg border-b-2 border-gray-200 p-2"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            />
            
            <input 
            type="text"
            placeholder='Education'
            className="outline-none text-base sm:text-lg border-b-2 border-gray-200 p-2"
            value={education}
            onChange={(e) => setEducation(e.target.value)}
            />

            <input 
            type="text"
            placeholder='Google Scholar (if applicable): '
            className="outline-none text-base sm:text-lg border-b-2 border-gray-200 p-2"
            value={scholar}
            onChange={(e)=>setScholar(e.target.value)}
            />

            <textarea 
            type="text"
            placeholder='Bio:'
            className="outline-none text-base sm:text-lg border-b-2 border-gray-200 p-2"
            value={bio}
            onChange={(e)=>setBio(e.target.value)}
            />
            
            
            <button 
            className="bg-black text-white mt-3 p-3 rounded-full cursor-pointer outline-none hover:shadow-md transition-all duration-500 ease-in-out"
            onClick={(saveProfile)}
            >
                Save
            </button>   
        </div>
    )
}

export default Settings
