import React from "react";

function FormRadioInput(props) {
  const inputChangeHandler = (e) => {
    props.onChange(e.target.value);
  };

  const options = props.options.map((option, i) => {
    return (
      <div key={i} className="flex items-center mr-12">
        <input
          className="mr-2"
          type="radio"
          id={option}
          name={props.name}
          value={option === "yes" ? true : false}
          defaultChecked={option === "no"}
          onChange={inputChangeHandler}
        />
        <label htmlFor={option} className="capitalize -mt-1">
          {option}
        </label>
      </div>
    );
  });

  return (
    <fieldset className={`mb-8 md:mb-12 md:flex ${props.className}`}>
      <label className="block mb-4 md:mb-0 md:mr-12 font-semibold">
        {props.label}
      </label>
      <div className="flex">{options}</div>
    </fieldset>
  );
}

export default FormRadioInput;
