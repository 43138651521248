import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Abstract from "./Abstract";
import UploadAssets from "./UploadAssets";
import GettingStarted from "./GettingStarted";
import Keywords from "./Keywords";
import Captions from "./Captions";
import PreviewVideo from "./PreviewVideo";
import ProgressSteps from "./ProgressSteps";
import ProgressStepsActions from "./ProgressStepsActions";
import ShareVideo from "./ShareVideo";
import { useSelector } from "react-redux";
import ChooseAssets from "./ChooseAssets";
import jwtDecode from "jwt-decode";
import { BaseURL } from "../../../utils/config";
import Aos from "aos"

// progress steps
const steps = [
  "Getting started",
  "Abstract",
  "Keywords",
  "Upload Assets",
  "Choose Assets",
  "Captions",
  "Preview",
  "Share",
];

const ACCESS_KEY = "UHu53APvPmM5HhrQH-dxocMXy4njM63SDHNPymap5FY";

const NewVideo = (props) => {
  const { token, user } = useSelector((state) => state.user);
  if (user) {
    console.log("TOKENS= " + token + " USER= " + user);
  }
  const navigate = useNavigate();

  const localToken = localStorage.getItem("user-token");
  const { id } = jwtDecode(localToken);

  // storing current step
  const [currentStep, setCurrentStep] = useState(1);
  const [images, setImages] = useState([{ path: "", caption: "" }]);
  const [selectedImagesPath, setSelectedImagesPath] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [uploadLoading, setUploadLoding] = useState(true);

  const [videoTitle, setVideoTitle] = useState();
  const [videoUrl, setVideoUrl] = useState();
  const [abstract, setAbstract] = useState("");
  const [allCaptions, setAllCaptions] = useState([]);
  const [keywords, setKeywords] = useState([]); //keywords generated from abstract
  const [reference, setReference] = useState();
  const [community, setCommunity] = useState();
  const [communityId, setCommunityId] = useState();

  const [uploadedImagesURL, setUploadedImagesURL] = useState([]);
  const [imageKeywords, setImageKeywords] = useState([]); //keywords added to each image in UploadAssets.jsx

  const [isNextEnable, setIsNextEnable] = useState(true);
  var allKeywords = [];

  // abstract
  const abstractRef = useRef();

  // identify step specific component fn
  const stepSpecificCompnentFn = (step) => {
    switch (step) {
      case 1:
        return <GettingStarted />;

      case 2:
        return <Abstract ref={abstractRef} setAbstract={setAbstract} />;

      case 3:
        return <Keywords setKeywords={setKeywords} keywords={keywords} />;

      case 4:
        return (
          <UploadAssets
            setImageKeywords={setImageKeywords}
            imageKeywords={imageKeywords}
            uploadedImagesURL={uploadedImagesURL}
            setUploadedImagesURL={setUploadedImagesURL}
          />
        );

      case 5:
        return (
          <ChooseAssets
            setImages={setImages}
            images={images}
            setSelectedImagesPath={setSelectedImagesPath}
            uploadedImagesURL={uploadedImagesURL}
            setUploadedImagesURL={setUploadedImagesURL}
            setIsNextEnable={setIsNextEnable}
          />
        );

      case 6:
        return (
          <Captions
            selectedImages={selectedImagesPath}
            setAllCaptions={setAllCaptions}
          />
        ); //adding captions

      case 7:
        return <PreviewVideo isLoading={isLoading} />; //previewing video, add title, add keywords

      case 8:
        return (
          <ShareVideo
            uploadLoading={uploadLoading}
            abstract={abstract}
            keywords={keywords}
            setVideoTitle={setVideoTitle}
            setCommunity={setCommunity}
            setCommunityId={setCommunityId}
            setReference={setReference}
          />
        ); //share

      default:
        return;
    }
  };

  console.log(uploadedImagesURL);

  const stepSpecificCompnent = stepSpecificCompnentFn(currentStep);

  const handleClick = async (direction) => {
    let newStep = currentStep;
    const token = await localStorage.getItem("user-token");
    const decoded = jwtDecode(token || "") || null;
    const id = decoded.userId;

    // step 1 -> without any rule, user should be able go to the next step
    // step 2 -> the abstaract must not be empty
    if (
      direction === "next" &&
      newStep === 2 &&
      abstractRef.current.value.length === 0
    ) {
      // setError(true);
      return;
    }

    if (direction === "next" && newStep === 2) {
      const abstract = abstractRef.current.value;
      const res = await fetch(`${BaseURL}/video/extract-keywords`, {
        method: "POST",
        body: JSON.stringify({
          input: abstract,
        }),
        headers: {
          Accept: "application/json",
          "Content-type": "application/json",
        },
      });
      const allKeywords = await res.json();
      const firstSixKeywords = allKeywords.slice(0, 6).map((keyword) => {
        return { value: keyword, viewMode: true, disabled: false };
      });
      setKeywords(firstSixKeywords);
    }

    // remove keywords on back
    if (direction !== "next" && newStep === 2) setKeywords([]);

    // step 3
    // disable the next button if nr of keywords is less than 4
    if (direction === "next" && newStep === 3 && keywords.length < 5) {
      console.log("test");
      return;
    }

    // everything ok for step 3 -> send request to unsplash api
    if (direction === "next" && newStep === 3) {
      const keywordsValue = keywords.map((keyword) => {
        return keyword.value;
      });
      //const res = await fetch(
      //  `https://api.unsplash.com/search/photos?client_id=${ACCESS_KEY}&query=${keywordsValue}&page=${1}&per_page=30`
      //);
      for (let i = 0; i < imageKeywords.length; i++) {
        const res = await fetch(`${BaseURL}/image/get-images`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            keywords: imageKeywords[i],
          }),
        });

        console.log("imageKeywords[i]:::" + imageKeywords[i]);

        const data = await res.json();
        const imgs = data.map((img) => {
          return { path: img.imageUrl, caption: "", selected: false };
        });
        console.log(imgs);
        setImages(imgs);
        //to update setImages((prev)=>[...prev,imgs])
      }
    }

    if (direction === "next" && newStep === 4) {
      for (let i = 0; i < keywords.length; i++) {
        const res = await fetch(`${BaseURL}/image/get-images`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            keywords: keywords[i],
            userId: id,
          }),
        });

        const data = await res.json();
        const imgs = data.map((img) => {
          return { path: img.imageUrl, caption: "", selected: false };
        });
        console.log(imgs);
        setImages(imgs);
        //to update setImages((prev)=>[...prev,imgs])
      }
    }

    //selected images will be processed
    if (direction === "next" && newStep === 5) {
      const token = await localStorage.getItem("user-token");
      const decoded = jwtDecode(token || "") || null;
      const id = decoded.userId;

      if (isNextEnable) {
        //variables to be sent to backend to generate video
        selectedImagesPath[0]["caption"] = "test200";
        console.log(selectedImagesPath);
        console.log(abstract);
        allKeywords = keywords.map((keyword) => keyword.value);
        console.log(allKeywords);
        console.log(selectedImagesPath);

        //delete local video
        fetch(`${BaseURL}/video/delete-local-generated-video`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: id,
          }),
        })
          .then((res) => console.log("generated video deleted"))
          .then(() => {
            fetch(`${BaseURL}/video/process-images`, {
              method: "POST",
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
              body: JSON.stringify({
                sourceImages: selectedImagesPath,
                userId: id,
              }),
            }).then((res) => console.log(res));
          });
      }
    }

    if (direction === "next" && newStep === 6) {
      console.log("HELLO ", { allCaptions });
      fetch(`${BaseURL}/video/generate-video`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: user.id,
          captions: allCaptions,
        }),
      })
        .then(() => setIsLoading(true))
        .then(() => {})
        .finally(() => setIsLoading(false));
    }

    if (currentStep === 7) {
      fetch(`${BaseURL}/video/upload-video`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          userId: user.id,
        }),
      })
        .then((res) => res.json())
        .then((data) => setVideoUrl(data.url))
        .finally(() => setUploadLoding(false));
    }

    if (direction === "next" && currentStep === 8) {
      if (user) {
        fetch(`${BaseURL}/video/add-video`, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: user.id,
            videoUrl: videoUrl,
            communityId: communityId,
            title: videoTitle,
            abstract: abstract,
            reference: reference,
            keywords: keywords.map((k) => k.value),
          }),
        })
          .then(() => {
            console.log("VIDEO ADDED TO DB");
            props.setNewVideoAdded((newVideoAdded) => newVideoAdded + 1);
            navigate("/", { replace: true });
          })
          .catch(() => console.log("ERROR ADDING VIDEO TO DB"));
      }
      console.log("TITLE = " + videoTitle);
      console.log("VIDEO URL = " + videoUrl);
      console.log("REFERENCE = " + reference);
      console.log("COMMUNITY = " + community.value);
      console.log("COMMUNITY-ID = " + communityId);
      console.log("CAPTIONS = " + allCaptions);
      console.log("KEYWORDS = " + keywords.map((k) => k.value));
    }

    direction === "next" ? newStep++ : newStep--;
    newStep > 0 && newStep <= steps.length && setCurrentStep(newStep);
  };

  useEffect(() => {}, [videoUrl]);

  useEffect(()=> {
    Aos.init({duration:1000})
  })

  return (
    <div data-aos="fade-up" className="mx-auto mt-10 flex px-4 h-fit w-11/12 flex-col rounded-md bg-white py-16 border-2 ">
      {/* Progress steps */}
      <ProgressSteps steps={steps} currentStep={currentStep} />

      {/* step components */}
      <div className="flex-auto">{stepSpecificCompnent}</div>

      {/* Progress step action buttons */}
      <ProgressStepsActions
        handleClick={handleClick}
        currentStep={currentStep}
        steps={steps}
        isNextEnable={isNextEnable}
      />
    </div>
  );
};

export default NewVideo;
