import React, { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import Aos from "aos";
import LogoPng from "../Assets/ViaRika-logo.png";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import {
  ContactButton,
  HeaderContainer,
  ListContainer,
  ListItem,
  Logo,
  LogoText,
  NavigationBox,
} from "./HeaderStyles";
import ViaRikaVideo from "../dark30.mp4";

function Header() {
  const [isOpen, setIsOpen] = useState(false);
  const videoRef = useRef();

  const handleOpen = () => {
    setIsOpen((prevStat) => !prevStat);
  };

  useEffect(() => {
    Aos.init({ duration: 400, easing: "ease-in-out", disable: "mobile" });
    if (videoRef.current.paused) {
      videoRef.current.play();
    }
  });

  return (
    <>
      <div className="navigation-icon">
        {isOpen ? (
          <CloseIcon
            onClick={handleOpen}
            fontSize="large"
            sx={{ color: "whitesmoke", height: "120px", width: "50px" }}
          />
        ) : (
          <MenuIcon
            onClick={handleOpen}
            fontSize="large"
            sx={{ color: "whitesmoke", height: "120px", width: "50px" }}
          />
        )}
      </div>

      <div className="video">
        <video
          autoPlay={true}
          ref={videoRef}
          loop
          width={"100%"}
          height={"100%"}
        >
          <source src={ViaRikaVideo} />
        </video>
      </div>
      <HeaderContainer
        data-aos="fade-down"
        className={`${!isOpen ? "nav-close" : "nav-open"}`}
      >
        <Logo>
          <img src={LogoPng} alt="ViaRika-logo" />
          <LogoText>
            <NavLink
              style={{
                color: " rgba(210, 160, 16, 1)",
                fontWeight: "700",
                fontStyle: "italic",
                letterSpacing: "1px",
                fontSize: "1.5rem",
              }}
              className="nav-link"
              to={"/"}
            >
              ViaRika.com
            </NavLink>
          </LogoText>
        </Logo>

        <NavigationBox>
          <ListContainer>
            {/* <ListItem>
                <NavLink className="nav-link" to={"/landing/login"}>
                  Home
                </NavLink>
              </ListItem> */}
            <ListItem>
              <NavLink className="nav-link" to="/landing/community">
                Community
              </NavLink>
            </ListItem>

            <ListItem>
              <NavLink className="nav-link" to={"/landing/blog"}>
                Blog
              </NavLink>
            </ListItem>

            <ListItem>
              <NavLink className="nav-link" to={"landing/signup"}>
                Signup
              </NavLink>
            </ListItem>

            <ListItem>
              <NavLink className="nav-link" to={"landing/contact"}>
                Contact
              </NavLink>

            </ListItem>
          </ListContainer>
        </NavigationBox>
      </HeaderContainer>
    </>
  );
}

export default Header;