import React, { useState, useRef, useEffect } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { AiFillCheckCircle } from "react-icons/ai";
import axios from "axios";
import { storage } from "../../../firebase/firebase.js";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";

const ChooseAssets = (props) => {
  const [limit, setLimit] = useState(0);
  const [isLimited, setIsLimited] = useState(false);
  const uploadRef = useRef(null);
  const formData = new FormData();
  const selectedImagesPath = [{ path: "", caption: "" }];

  /* UNUSABLE FUNCTION
  const confirmFirebaseUpload = () =>{

    for(let i=0; i < uploadedImages.length; i++)
    {
      const imageRef = ref(storage, `images/${uploadedImages[i].name}`)
      //formData.append("file",uploadedImages[i]);
      uploadBytes(imageRef, uploadedImages[i])
      .then((snapshot)=>{
        getDownloadURL(snapshot.ref).then((url)=>{
          props.setUploadedImagesURL((prevState)=>[...prevState,url])
          props.setSelectedImagesPath((prev)=>[...prev,{path:url,caption:'test'}]);
        })
      })
    }

  }
  */

  /*
  UNUSABLE FUNCTION
  const confirmCloudinaryUpload = () => {
    const url = 'https://api.cloudinary.com/v1_1/viarika/image/upload'
    const formData = new FormData();

    for(let i=0; i < uploadedImages.length; i++)
    {
      let file = uploadedImages[i];
      formData.append('file',file);
      formData.append('upload_preset','reactupload');

      fetch(url,{
        method:'POST',
        body:formData
      })
      .then((res)=>{return res.text()})
      .then((data)=>console.log(data));

    }
  }
  */

  const reset = () => {
    props.images.map((img) => {
      return (img.selected = false);
    });
    props.setSelectedImagesPath([]);
  };

  const selectImageHandler = (index) => {
    const newImagesArr = props.images.map((image, i) => {
      const img =
        index === i && limit < 9
          ? { ...image, selected: !image.selected }
          : { ...image };
      return img;
    });

    // change state, diable button if limit is less than 4
    if (limit >= 3) {
      props.setIsNextEnable(true);
    } else {
      props.setIsNextEnable(false);
    }

    props.setImages(newImagesArr);

    newImagesArr.map((img) =>
      img.selected === true
        ? selectedImagesPath.push({ path: img.path, caption: "test" })
        : ""
    );
    selectedImagesPath.shift(); //to delete the first empty element
    props.setSelectedImagesPath(selectedImagesPath);
    //newImagesArr.map((img)=>img.selected === true ?  props.setSelectedImagesPath((prev)=>[...prev,{path:img.path,caption:'test'}]):'');
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  const images = props.images.map((image, i) => {
    return (
      <div
        key={i}
        className="relative"
        onClick={() => {
          selectImageHandler(i);

          if (!image.selected) {
            setLimit(limit + 1);
          } else {
            setLimit(limit - 1);
          }
          console.log(limit);
        }}
      >
        <Modal
          hideBackdrop
          open={limit > 9 ? true : false}
          onClose={limit <= 9 ? true : false}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={modalStyle}>
            <Typography id="modal-modal-title" variant="h6" component="h2">
              You can only choose 9 Images
            </Typography>

            <button
              className="flex flex-row rounded border border-blue-500 bg-transparent py-2 px-4 font-semibold text-blue-700 hover:border-transparent hover:bg-black hover:text-white"
              onClick={() => {
                setLimit((prevLimit) => (prevLimit = -1));
                props.images.map((img) => {
                  return (img.selected = false);
                });

                uploadRef.current.value = null;
                props.setSelectedImagesPath([]);
              }}
            >
              Reset Selections
            </button>
          </Box>
        </Modal>

        <img
          className="h-[20rem] object-fill"
          src={image.path}
          alt={image.caption}
        />

        {image.selected && (
          <div className="absolute top-0 left-0 flex h-full w-full items-center justify-center bg-blackOverlay">
            {<AiFillCheckCircle className="text-[4rem] text-white" />}
          </div>
        )}
      </div>
    );
  });
  return (
    <div>
      <div className="mb-10 flex flex-row gap-10 p-2">
        <button
          className="rounded border border-blue-500 bg-transparent px-4 font-semibold text-blue-700 hover:border-transparent hover:bg-black hover:text-white"
          onClick={reset}
        >
          Reset
        </button>
      </div>

      <div className="flex flex-wrap gap-10">{images}</div>

      {limit < 4 && (
        <p className="mt-10 font-bold text-red-600">
          Please select more than 4 images.
        </p>
      )}
    </div>
  );
};

export default ChooseAssets;
