import React from "react";

const Abstract = React.forwardRef((props, ref) => {
  return (
    <div data-aos="fade-up" className="h-full">
      <textarea
        className="h-full w-full rounded border-2 border-gray-300 p-6 text-[1.4rem]"
        name=""
        id=""
        ref={ref}
        onChange={(e)=>props.setAbstract(e.target.value)}
        cols="30"
        rows="10"
        placeholder="Please insert your abstract..."
      ></textarea>
    </div>
  );
});

export default Abstract;
