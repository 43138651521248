import React, { useEffect, useState } from "react";
import { NavLink, Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { BsQuestionCircle } from "react-icons/bs";
import { categories } from "../utils/data";
import NavigationLink from "../components/UI/NavigationLink";

// icons
import logo from "../assets/logo.png";
import { BsFillJournalBookmarkFill } from "react-icons/bs";
import { IoNewspaperOutline } from "react-icons/io5";
import { HiInformationCircle } from "react-icons/hi";
import { AiFillMail } from "react-icons/ai";
import { FiHome } from "react-icons/fi";
import { HiOutlineMail } from "react-icons/hi";
import { ActionButton } from "./UI/ActionButton";
import { Profile } from "./UI/Profile";
import QuestionsPage from "../pages/nested-pages/QuestionsPage";

// component function
const Sidebar = (props) => {

  console.log(`user: ${props.user}`)
  
  const [isOpen, setIsOpen] = useState(false);

  // FIXME temporary solution for closing the menu when an item was clicked
  const { pathname } = useLocation();
  useEffect(() => {
    setIsOpen(false);
  }, [pathname]);

  const navButtonHandler = () => {
    setIsOpen((state) => {
      return !state;
    });
  };

  return (
    <>
      <nav
        className={`relative z-40 flex items-center justify-between bg-white px-[2.4rem]
         py-[1.6rem] text-[1.6rem] shadow md:h-screen md:flex-col md:items-start
          md:py-[1.6rem] md:text-[1.8rem]`}
      >
        <button
          onClick={navButtonHandler}
          className="nav__burger flex flex-col gap-[.6rem] md:hidden "
        >
          <span
            className={`burger-line relative block h-[.4rem] w-[3.2rem] rounded-2xl bg-black ${
              isOpen ? "translate-y-[10px] rotate-45" : ""
            }`}
          ></span>
          <span
            className={`burger-line relative block h-[.4rem] w-[3.2rem] rounded-2xl bg-black ${
              isOpen ? "opacity-0" : ""
            }`}
          ></span>
          <span
            className={`burger-line relative block h-[.4rem] w-[3.2rem] rounded-2xl bg-black ${
              isOpen ? "-translate-y-[10px] -rotate-45" : ""
            }`}
          ></span>
        </button>

        <div className="nav__logo w-[12rem] md:mb-[2.4rem]">
          <Link to="/" className="inline-block">
            <img src={logo} alt="logo" className="w-full" />
          </Link>
        </div>

        <div className="md:hidden">
          {!props.user ? (
            <ActionButton
              to="/account/login"
              text="Login"
              active={false}
              className="py-2 px-6"
            />
          ) : (
            <Profile
              image={props.user?.image}
              name={props.user.firstname}
              userId={props.user.id}
            />
          )}
        </div>

        <div
          className={`nav__navigation hidden gap-[1.6rem] 
           overflow-hidden md:flex md:h-full md:flex-col`}
        >
          <ul className="flex flex-col gap-2">
            <NavigationLink to="/home" text="Home" icon={<FiHome />} />
            <NavigationLink
              to="/home/questions"
              text="Questions"
              icon={<BsQuestionCircle />}
            />
            <NavigationLink
              to="/home/about"
              text="About"
              icon={<HiInformationCircle />}
            />
            <NavigationLink
              to="/home/blog"
              text="Blog & News"
              icon={<IoNewspaperOutline />}
            />
            <NavigationLink
              to="/home/contact-Us"
              text="Contact Us"
              icon={<HiOutlineMail />}
            />
            {/*
            <NavigationLink
              to="/home/booking-an-appointment"
              text="Book An Appointment"
              icon={<BsFillJournalBookmarkFill />}
            />
             */}
          </ul>
          {!props.user && (
            <div className="nav__buttons mb-[.8rem] flex flex-row gap-4 ">
              <ActionButton
                to="/account/login"
                text="Login"
                className="w-full"
              />

              <ActionButton
                to="/account/signup"
                text="Sign Up"
                className="w-full"
                active={true}
              />
            </div>
          )}

          <span className="divider block h-[.2rem] rounded bg-gray-400 opacity-50" />

          <div className="nav__communities flex h-full flex-col overflow-hidden ">
            <h3 className="mb-[1.6rem] text-center text-gray-400">
              Communities
            </h3>
            <ul className="h-full overflow-y-scroll">
              {categories.slice(0, categories.length - 1).map((category) => (
                <NavigationLink
                  to={`/home/category/${category.name}`}
                  text={category.name}
                  icon={
                    <img
                      src={category?.image}
                      className="2-8 h-8 rounded-full shadow-sm"
                      alt=""
                    />
                  }
                  key={category.name}
                />
              ))}
            </ul>
          </div>
        </div>
      </nav>

      {/* Nav on mobile */}
      <div
        className={`mobile__nav  fixed z-30 flex flex-col gap-[1.6rem] transition-[top] duration-1000 ease-out ${
          isOpen ? "top-0" : "-top-full"
        }  h-screen w-screen overflow-hidden bg-white px-[2.4rem] pt-[9.6rem] pb-[2.4rem] text-[1.8rem]  md:hidden`}
      >
        <ul className="flex flex-col gap-2">
          <NavigationLink to="/" text="Home" icon={<FiHome />} />
          <NavigationLink
            to="/about"
            text="About"
            icon={<HiInformationCircle />}
          />
          <NavigationLink
            to="/blog"
            text="Blog & News"
            icon={<IoNewspaperOutline />}
          />
          <NavigationLink
            to="/contact-Us"
            text="Contact Us"
            icon={<AiFillMail />}
          />
          
      {/*<NavigationLink
            to="/booking-an-appointment"
            text="Book An Appointment"
            icon={<BsFillJournalBookmarkFill />}
          />
      */}
        </ul>

        <span className="divider block h-[.2rem] rounded bg-gray-400 opacity-50" />

        <div className="nav__communities flex h-full flex-col overflow-y-hidden ">
          <h3 className="mb-[1.6rem] text-center text-gray-400">Communities</h3>
          <div className="h-full overflow-y-scroll pr-4">
            <ul>
              {categories.slice(0, categories.length - 1).map((category) => (
                <NavigationLink
                  to={`/category/${category.name}`}
                  text={category.name}
                  icon={
                    <img
                      src={category?.image}
                      className="2-8 h-8 rounded-full shadow-sm"
                      alt=""
                    />
                  }
                  key={category.name}
                />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
