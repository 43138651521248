import React, { useRef, useState } from "react";
import { useNavigate, Link, redirect } from "react-router-dom";

// custom hooks
import useInput from "../../hooks/useInput-hook";
import { BaseURL } from "../../utils/config";

// components
import Button from "../UI/Button";
import FormInput from "../UI/FormInput";
import FormCard from "../UI/FormCard";

// icons
import logo from "../../assets/logo.png";
import { FcGoogle } from "react-icons/fc";
import { ImSpinner } from "react-icons/im";
import { userActions } from "../../store/userSlice";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [password, setPassword] = useState()

  const uniqueIdTemp = window.location.toString().split('uniqueId:')[1];
  const uniqueId = uniqueIdTemp.split('?')[0]
  const userId = window.location.toString().split('userId:')[1];

  console.log(uniqueId);
  console.log(userId);

  const formSubmitHandler = ()=>{
    
    fetch(`${BaseURL}/auth/verify-password/${uniqueId}/${userId}`,{
      headers:{
        Accept:"application/json",
        "Content-Type":"application/json"
      },
      method:"POST",
      body:JSON.stringify({
        newPassword:password
      })
    }).then((res)=>{
      if(res.status === 200){
        console.log(res)
        navigate('/account/login?redirect=toHome')
      }
    })

  }
  
  

  return (
    <div >
      <FormCard>
      <div>
      <div className="mb-12 flex justify-center self-center ">
        <img src={logo} className="w-60" alt="logo" />
      </div>

      <div
        className={`flex h-340  w-full flex-col text-[1.6rem] justify-center  `}
      >
        <div>
          <FormInput
            label="New Password"
            inputType="text"
            onChange={(e)=>setPassword(e.target.value)}
          />
        </div>

        <div className="flex flex-col items-baseline justify-between md:flex-row md:items-baseline">
          <Button
            className="mb-4 bg-blue-700 text-white hover:bg-blue-600 md:mb-0 md:mr-8 md:w-1/3"
            buttonText="Set New Password"
            onClick={formSubmitHandler}
          />
        </div>
        </div>
    </div>
      </FormCard>
    </div>
  )
}

export default ForgotPassword
