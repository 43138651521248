import React, { useImperativeHandle, useRef } from "react";

//components
import FormLabel from "./Form/FormLabel";

//icons
import { BsCheckLg } from "react-icons/bs";
import { IoWarningOutline } from "react-icons/io5";
import Input from "./Form/Input";

const FormInput = React.forwardRef((props, ref) => {
  // Focus the input if it was invalid after submission
  const inputRef = useRef();
  const activate = () => {
    inputRef.current.focus();
  };

  useImperativeHandle(ref, () => {
    return {
      focus: activate,
    };
  });

  return (
    // input height = button height
    <fieldset className={`mb-8 md:mb-12 ${props.className}`}>
      <div className="flex flex-col">
        <FormLabel
          label={props.label}
          htmlFor={props.htmlFor}
          required={props.required}
        />
        <Input
          ref={inputRef}
          id={props.htmlFor}
          required={props.required}
          value={props.value}
          onChange={props.onChange}
          type={props.inputType}
          isTouched={props.isTouched}
          isValid={props.isValid}
          showError={props.showError}
          requirementMessage={props.requirementMessage}
          error={props.error}
        />
      </div>
    </fieldset>
  );
});

export default FormInput;
