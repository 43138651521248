import React, { useRef, useState, useEffect } from "react";
import { useNavigate, Link, redirect } from "react-router-dom";

// custom hooks
import useInput from "../../hooks/useInput-hook";
import { BaseURL } from "../../utils/config";

// components
import Button from "../UI/Button";
import FormInput from "../UI/FormInput";
import FormCard from "../UI/FormCard";

// icons
import logo from "../../assets/logo.png";
import { FcGoogle } from "react-icons/fc";
import { ImSpinner } from "react-icons/im";
import { userActions } from "../../store/userSlice";



const EmailVerification = () => {

  const[Email, setEmail] = useState();
  const[emailVerificationSent, setEmailVerificationSent] = useState(false);

  const formSubmitHandler = () =>{
  
    fetch(`${BaseURL}/auth/email-verify`,{
      headers:{
        Accept:"application/json",
        "Content-Type":"application/json"
      },
      method:"POST",
      body:JSON.stringify({
        email:Email
      })
    }).then((res)=>{
      setEmailVerificationSent(true)
      console.log(res)
    })
  }

  useEffect(()=>{

  },[])

  console.log(Email)



  return (
    <div >
      <FormCard>
      <div>
      <div className="mb-12 flex justify-center self-center ">
        <img src={logo} className="w-60" alt="logo" />
      </div>

      <div
        className={`flex h-340  w-full flex-col text-[1.6rem] justify-center  `}
      >
        <div>
          <FormInput
            label="email address"
            inputType="text"
            onChange={(e)=>setEmail(e.target.value)}
          />
        </div>

        <div className="flex  flex-col items-baseline justify-between md:flex-row md:items-baseline">
          <Button
            className="mb-4 bg-blue-700 text-white hover:bg-blue-600 md:mb-0 md:mr-8 md:w-1/3"
            buttonText="Verify Email"
            onClick={formSubmitHandler}
          />
        
        </div>
        
        {
          emailVerificationSent ? (
            <div>
              <h1>EMAIL VERIFICATION SENT</h1>
              </div>
          ):(
            <div>
              </div>
          )
        }

        <div className="my-8 flex h-12 items-center text-[1.6rem]">
        <span className="block h-0.5 w-2/5 bg-gray-300"></span>
        <span className="block w-1/5 text-center">ViaRika</span>
        <span className="block h-0.5 w-2/5 bg-gray-300"></span>

      </div>
        </div>
    </div>
      </FormCard>
    </div>
  )
}

export default EmailVerification
