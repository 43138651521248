import React from "react"
import { PopupWidget } from "react-calendly";

export default function Calendly() {
    return (
      <div className="App">
        <PopupWidget
          url="https://calendly.com/viarika/30min"
          rootElement={document.getElementById("root")}
          text="Book a Demo"
          textColor="#000000"
          color="#D2A010"
        />
      </div>
    );
  }