// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCEsF7QxEb7fT7zLdByARoP-uJdrZxIiRk",

  authDomain: "viarikauploads.firebaseapp.com",

  projectId: "viarikauploads",

  storageBucket: "viarikauploads.appspot.com",

  messagingSenderId: "873429648695",

  appId: "1:873429648695:web:46876aa3a1fdc6a29506b2",

  measurementId: "G-QNVVQSBBG7",
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);

const storage = getStorage(app);

export { storage, app as default };

// // Import the functions you need from the SDKs you need

// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// import {getStorage} from 'firebase/storage';

// // TODO: Add SDKs for Firebase products that you want to use

// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration

// // For Firebase JS SDK v7.20.0 and later, measurementId is optional

// const firebaseConfig = {

//   apiKey: "AIzaSyBDPoUz3UUcfgmZyKFULsdUzSY1kB_m5_Q",

//   authDomain: "viarikaimages.firebaseapp.com",

//   projectId: "viarikaimages",

//   storageBucket: "viarikaimages.appspot.com",

//   messagingSenderId: "397384243250",

//   appId: "1:397384243250:web:9ddb220608e9e0eb33442b",

//   measurementId: "G-DDGVPGCTFB"

// };

// // Initialize Firebase

// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
// export const storage = getStorage(app);
