import React from "react";

import faqsData from "./faqsData";
import FAQCCORDIAN from "./FAQCCORDIAN";

import Aos from "aos";
import { useEffect } from "react";

function FAQS() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  });

  return (
    <>
      <div className="faqs-container" data-aos="fade-in">
        <h1 className="my-10 text-center text-[4.5rem] font-bold text-white">
          Frequently Asked Questions
        </h1>
        <div className="flex justify-center mr-40">
        <div className="flex flex-col items-start">
          {faqsData.map(({ question, answer, id }, i) => {
            return (
              <FAQCCORDIAN
                question={question}
                answer={answer}
                id={id}
                index={i}
              />
            );
          })}
        </div>
        </div>
      </div>
    </>
  );
}

export default FAQS;