import React from "react";
import { FaQuestionCircle, FaVideo } from "react-icons/fa";
import { MdCloudUpload } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import {GiClapperboard} from 'react-icons/gi'

function SelectVideoType() {
  const navigate = useNavigate()

  const createNewProjectAndRedirect = ()=>{
    fetch('https://api.viarika.com/projectCreate',{
      method:"POST",
      headers:{
        Accept:"application/json",
        "Content-Type":"application/json"
      },
    })
    .then((res)=>res.json())
    .then((data)=>{
      console.log(data)
      window.location.replace(`https://viarikanodejs.herokuapp.com/editor?projectId=${data.project_id}`)
    })
  }

  return (
    <div className="flex h-screen">
      <div className="m-auto">
        <h1 className="text-center text-[24px] font-semibold">I want to</h1>
        <div className="m-5 flex max-h-[1000px] max-w-[700px] flex-wrap justify-center gap-6 ">

        
        <button
            onClick={()=>{
              createNewProjectAndRedirect()
            }}
            className="group relative flex h-[150px] min-h-[50px] w-[150px] min-w-[50px] flex-col items-center justify-center rounded px-5 py-2.5 font-medium text-white"
          >
            <span className="absolute top-0 left-0 h-full w-full rounded bg-gradient-to-br from-red-500 to-white opacity-50 blur-sm filter"></span>
            <span className="absolute inset-0 mt-0.5 ml-0.5 h-full w-full rounded bg-gradient-to-br from-red-600 to-white-500 opacity-50 filter group-active:opacity-0"></span>
            <span className="absolute inset-0 h-full w-full rounded bg-gradient-to-br from-red-600 to-white-500 shadow-xl filter transition-all duration-200 ease-out group-hover:blur-sm group-active:opacity-0"></span>
            <span className="absolute inset-0 h-full w-full rounded bg-gradient-to-br from-red-500 to-white-500 transition duration-200 ease-out"></span>
            <GiClapperboard size="30" className="relative" />
            <span className="relative text-[18px]">Creat/Edit Video</span>
          </button>


          <Link
            to="/home/upload-video"
            className="group relative flex  h-[150px] min-h-[50px] w-[150px] min-w-[50px] flex-col items-center justify-center rounded px-5 py-2.5 font-medium text-white"
          >
            <span className="absolute top-0 left-0 h-full w-full rounded bg-gradient-to-br from-purple-600 to-blue-500 opacity-50 blur-sm filter"></span>
            <span className="absolute inset-0 mt-0.5 ml-0.5 h-full w-full rounded bg-gradient-to-br from-purple-600 to-blue-500 opacity-50 filter group-active:opacity-0"></span>
            <span className="absolute inset-0 h-full w-full rounded bg-gradient-to-br from-purple-600 to-blue-500 shadow-xl filter transition-all duration-200 ease-out group-hover:blur-sm group-active:opacity-0"></span>
            <span className="absolute inset-0 h-full w-full rounded bg-gradient-to-br from-blue-500 to-purple-600 transition duration-200 ease-out"></span>
            <MdCloudUpload size="30" className="relative" />
            <span className="relative text-[18px]">Upload Video</span>
          </Link>

          <Link
            to="/home/new-video"
            className="group relative flex  h-[150px] min-h-[50px] w-[150px] min-w-[50px] flex-col items-center justify-center rounded px-5 py-2.5 font-medium text-white"
          >
            <span className="absolute top-0 left-0 h-full w-full rounded bg-gradient-to-br from-orange-500 to-green-500 opacity-50 blur-sm filter"></span>
            <span className="absolute inset-0 mt-0.5 ml-0.5 h-full w-full rounded bg-gradient-to-br from-orange-600 to-green-500 opacity-50 filter group-active:opacity-0"></span>
            <span className="absolute inset-0 h-full w-full rounded bg-gradient-to-br from-orange-600 to-green-500 shadow-xl filter transition-all duration-200 ease-out group-hover:blur-sm group-active:opacity-0"></span>
            <span className="absolute inset-0 h-full w-full rounded bg-gradient-to-br from-orange-500 to-green-500 transition duration-200 ease-out"></span>
            <FaVideo size="30" className="relative" />
            <span className="relative text-[18px] text-center">Create Slideshow</span>
          </Link>

          <Link
            to="/home/ask-question"
            className="group relative flex  h-[150px] min-h-[50px] w-[150px] min-w-[50px] flex-col items-center justify-center rounded px-5 py-2.5 font-medium text-white"
          >
            <span className="absolute top-0 left-0 h-full w-full rounded bg-gradient-to-br from-slate-600 to-stone-600 opacity-50 blur-sm filter"></span>
            <span className="absolute inset-0 mt-0.5 ml-0.5 h-full w-full rounded bg-gradient-to-br from-slate-600 to-stone-600 opacity-50 filter group-active:opacity-0"></span>
            <span className="absolute inset-0 h-full w-full rounded bg-gradient-to-br from-slate-600 to-stone-600 shadow-xl filter transition-all duration-200 ease-out group-hover:blur-sm group-active:opacity-0"></span>
            <span className="absolute inset-0 h-full w-full rounded bg-gradient-to-br from-slate-600 to-stone-600 transition duration-200 ease-out"></span>
            <FaQuestionCircle size="30" className="relative" />
            <span className="relative text-[18px]">Ask question</span>
          </Link>



        </div>
      </div>
    </div>
  );
}

export default SelectVideoType;
