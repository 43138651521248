export const categories = [
  {
    communityId: "630901e6cfc18705d6124b95",
    name: "Astronomy",
    image:
      "https://assets3.thrillist.com/v1/image/2869853/792x528/scale;webp=auto;jpeg_quality=60;progressive.jpg",
  },
  {
    communityId: "63090a5f24e3bc8d66de5156",
    name: "Astrophysics",
    image:
      "https://earthsky.org/upl/2020/01/black-hole-dana-berry-nasa-1-e1580207116813.jpg",
  },
  {
    communityId: "63090a8924e3bc8d66de5158",
    name: "Archaeology",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/a/af/All_Gizah_Pyramids.jpg/800px-All_Gizah_Pyramids.jpg",
  },
  {
    communityId: "63090a9524e3bc8d66de515a",
    name: "Biology",
    image:
      "https://www.educationgist.com/wp-content/uploads/2021/08/visible-body-3d-biology-learn-social-3-300x150.png",
  },
  {
    communityId: "63090aa324e3bc8d66de515c",
    name: "Biotechnology",
    image:
      "https://thumbor.forbes.com/thumbor/fit-in/1200x0/filters%3Aformat%28jpg%29/https%3A%2F%2Fspecials-images.forbesimg.com%2Fimageserve%2F1034901762%2F0x0.jpg",
  },
  {
    communityId: "63090ab324e3bc8d66de515e",
    name: "Biophysics",
    image: "https://science.ubc.ca/sites/science.ubc.ca/files/Biophysics.jpg",
  },
  {
    communityId: "63090ac324e3bc8d66de5160",
    name: "Chemistry",
    image:
      "https://www.ukrgate.com/eng/wp-content/uploads/2021/05/External-Examination-in-Chemistry1.jpg",
  },
  {
    communityId: "63555abb50aa0b56eeae3490",
    name: "Computer Science",
    image:
      "https://www.openaccessgovernment.org/wp-content/uploads/2020/08/dreamstime_l_124110584.jpg",
  },
  {
    communityId: "63090ad524e3bc8d66de5162",
    name: "Engineering",
    image:
      "http://www.ceccoglobal.com/images/manufacturing-plan-e1392849473260.jpg",
  },
  {
    communityId: "63090ae124e3bc8d66de5164",
    name: "Geology",
    image:
      "http://www.pbs.org/wgbh/nova/assets/img/full-size/geology-quiz-merl.jpg",
  },
  {
    communityId: "630911f124e3bc8d66de5166",
    name: "Mathematics",
    image:
      "https://studyqa.com/media/upload/articles/images/Pure-mathematics-formul%C3%A6-blackboard%5B1%5D.jpg",
  },
  {
    communityId: "630911fd24e3bc8d66de5168",
    name: "Medicine",
    image:
      "https://y5a3y7k9.stackpathcdn.com/wp-content/uploads/2020/05/how-studying-works-768x512.jpg",
  },
  {
    communityId: "6309120824e3bc8d66de516a",
    name: "Microbiology",
    image: "http://prc.pushpagiri.in/wp-content/uploads/2015/06/micro1-1.jpg",
  },
  {
    communityId: "6309121424e3bc8d66de516c",
    name: "Nanotechnology",
    image:
      "https://th.bing.com/th/id/OIP.6vLg4qXIlCG0FsVmMkdVEAHaFc?pid=ImgDet&rs=1",
  },
  {
    communityId: "6309122424e3bc8d66de516e",
    name: "Neuroscience",
    image:
      "https://www.sigmaaldrich.com/deepweb/assets/sigmaaldrich/marketing/global/images/applications/research-and-disease-areas/brain/brain.png",
  },
  {
    communityId: "6309123624e3bc8d66de5170",
    name: "Nutrition",
    image:
      "https://static01.nyt.com/images/2019/02/28/opinion/28yun/28yun-superJumbo.jpg",
  },
  {
    communityId: "6309124224e3bc8d66de5172",
    name: "Logic",
    image:
      "https://upload.wikimedia.org/wikipedia/commons/thumb/a/ae/Aristotle_Altemps_Inv8575.jpg/1200px-Aristotle_Altemps_Inv8575.jpg",
  },
  {
    communityId: "6309138a24e3bc8d66de5176",
    name: "Physics",
    image:
      "https://t4.ftcdn.net/jpg/01/91/30/61/360_F_191306165_s3BaSe1JFZLtBhCs5iPLTXbG7PmAyw2h.jpg",
  },
  {
    communityId: "6309139d24e3bc8d66de5178",
    name: "Psychology",
    image: "https://wallpaperaccess.com/full/1415659.jpg",
  },
  {
    communityId: "630913b024e3bc8d66de517a",
    name: "Social Science",
    image:
      "https://c0.wallpaperflare.com/preview/287/860/54/team-ethnicity-group-hands.jpg",
  },
];

export const options = [
  { value: "Astronomy", label: "Astronomy" },
  { value: "Astrophysics", label: "Astrophysics" },
  { value: "Archaeology", label: "Archaeology" },
  { value: "Biology", label: "Biology" },
  { value: "Biotechnology", label: "Biotechnology" },
  { value: "Bipohysics", label: "Bipohysics" },
  { value: "Chemistry", label: "Chemistry" },
  { value: "Computer Science", label: "Computer Science" },
  { value: "Engineering", label: "Engineering" },
  { value: "Geology", label: "Geology" },
  { value: "Mathematics", label: "Mathematics" },
  { value: "Medicine", label: "Medicine" },
  { value: "Microbiology", label: "Microbiology" },
  { value: "Nanotechnology", label: "Nanotechnology" },
  { value: "Neuroscience", label: "Neuroscience" },
  { value: "Nutrition", label: "Nutrition" },
  { value: "Logic", label: "Logic" },
  { value: "Physics", label: "Physics" },
  { value: "Psychology", label: "Psychology" },
  { value: "Social Science", label: "Social Science" },
  { value: "", label: "" },
];

export const feedQuery = `*[_type == "pin"] | order(_createdAt desc) {
    image{
      asset->{
        url
      }
    },
        _id,
        title,
        destination,
        postedBy->{
          _id,
          userName,
          image
        },
        save[]{
          _key,
          postedBy->{
            _id,
            userName,
            image
          },
        },
      } `;

export const blogQuery = `*[_type == "blog"] | order(_createdAt desc) {
    image,
    title,
    content,
  }`;

export const pinDetailQuery = (pinId) => {
  const query = `*[_type == "pin" && _id == '${pinId}']{
      image{
        asset->{
          url
        }
      },
      _id,
      title,
      keywords, 
      about,
      category,
      destination,
      postedBy->{
        _id,
        userName,
        image
      },
     save[]{
        postedBy->{
          _id,
          userName,
          image
        },
      },
      comments[]{
        comment,
        _key,
        postedBy->{
          _id,
          userName,
          image
        },
      }
    }`;
  return query;
};

export const pinDetailMorePinQuery = (pin) => {
  const query = `*[_type == "pin" && category == '${pin.category}' && _id != '${pin._id}' ]{
      image{
        asset->{
          url
        }
      },
      _id,
      destination,
      postedBy->{
        _id,
        userName,
        image
      },
      save[]{
        _key,
        postedBy->{
          _id,
          userName,
          image
        },
      },
    }`;
  return query;
};

export const searchQuery = (searchTerm) => {
  const query = `*[_type == "pin" && title match '${searchTerm}*' || category match '${searchTerm}*' || about match '${searchTerm}*' || keywords match '${searchTerm}']{
          image{
            asset->{
              url
            }
          },
              _id,
              destination,
              postedBy->{
                _id,
                userName,
                image
              },
              save[]{
                _key,
                postedBy->{
                  _id,
                  userName,
                  image
                },
              },
            }`;
  return query;
};

export const userQuery = (userId) => {
  const query = `*[_type == "user" && _id == '${userId}']`;
  return query;
};

export const userCreatedPinsQuery = (userId) => {
  const query = `*[ _type == 'pin' && userId == '${userId}'] | order(_createdAt desc){
      image{
        asset->{
          url
        }
      },
      _id,
      destination,
      postedBy->{
        _id,
        userName,
        image
      },
      save[]{
        postedBy->{
          _id,
          userName,
          image
        },
      },
    }`;
  return query;
};

export const userSavedPinsQuery = (userId) => {
  const query = `*[_type == 'pin' && '${userId}' in save[].userId ] | order(_createdAt desc) {
      image{
        asset->{
          url
        }
      },
      _id,
      destination,
      postedBy->{
        _id,
        userName,
        image
      },
      save[]{
        postedBy->{
          _id,
          userName,
          image
        },
      },
    }`;
  return query;
};
