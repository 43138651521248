import React from "react";
// import ReactPlayer from "react-player/lazy";
import { Link } from "react-router-dom";
import { categories } from "../../utils/data";
import { DateAgo } from "../../utils/DateAgo";


import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";

const titleTextStyle = {
  maxWidth: "100%",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: 2,
  overflow: "hidden",
  textOverflow: "ellipsis",
};

function FeedCard({ videoInfo }) {
  let date = Date.parse(videoInfo.createdAt);
  const catagory = categories.filter((item) =>
    item.communityId.includes(videoInfo.communityId)
  );

  return (
    <Link
      to={`/home/watch/${videoInfo._id}`}
      style={{ textDecoration: "none" }}
      className="mx-2"
    >
      <div className="mb-8 w-[304px] cursor-pointer overflow-hidden rounded-2xl bg-white shadow-md transition duration-300 hover:scale-[1.02] hover:shadow-2xl">
        {/* <ReactPlayer
        url={videoInfo.videoUrl + "#t=1"}
        controls
        width="100%"
        height="202px"
      /> */}

        <Card sx={{ maxWidth: "100%", height: "100%" }}>
          <CardMedia
            sx={{ height: 200, width: "100%" }}
            image={videoInfo.imageUrl}
          />
          </Card>
          
        <div className="mt-2 flex gap-3 p-4">
          {/* user image */}
          {/* <div className="h-10 w-10 rounded-[50%] bg-slate-400"></div> */}
          <div className="">
            {catagory[0] && (
              <h2 className="my-3 w-fit rounded-md bg-cyan-200 py-[1px] px-3 text-center text-[13px] text-gray-600">
                {catagory[0].name}
              </h2>
            )}
            <h1
              className="max-h-[48.256px] min-h-[48px] max-w-[255px] text-[16px] font-medium"
              style={titleTextStyle}
            >
              {videoInfo.title}
            </h1>
            <h2 className="mt-1 text-[12px] text-gray-600">{DateAgo(date)}</h2>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default FeedCard;
