import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import UserAvtar from "../../UI/UserAvtar";
import AnswerComponent from "./AnswerComponent";
import { BaseURL } from "../../../utils/config";
import jwtDecode from "jwt-decode";
import PDFView from "../../PDFView";

function DisplayQuestion() {
  const params = useParams();
  const [questionUserData, setQuestionUserData] = useState({});

  console.log(JSON.stringify(questionUserData));

  let userId;
  const token = localStorage.getItem("user-token");
  if (token) {
    const decoded = jwtDecode(token);
    userId = decoded.userId;
  }

  useEffect(() => {
    fetch(`${BaseURL}/question/getQuestionById?questionId=${params.id}`)
      .then((res) => res.json())
      .then((data) => setQuestionUserData(data));
  }, []);

  return (
    <div className="flex justify-center">
      {questionUserData.question ? (
        <div className="mx-10 mb-10 w-full max-w-[1480px] overflow-hidden rounded-xl bg-white pb-10 shadow-xl">
          <div className="bg-slate-100 pb-4">
            <div className="mx-8 mb-10 flex items-center justify-between  pt-6">
              {/* Left component */}
              <div className="flex items-center">
                {/* user image */}
                <UserAvtar
                  userName={questionUserData.user.firstname}
                  className="h-[3.6rem] w-[3.6rem] items-start text-lg"
                />
                <div className="ml-4">
                  {/* user name */}
                  <h2 className="mt-1 text-2xl font-medium">
                    {questionUserData.user.firstname}{" "}
                    {questionUserData.user.lastname}
                  </h2>
                </div>
              </div>
              {/* Right component */}
            </div>
            {/* question asker title */}
            <h1 className="my-6 mx-6 text-[19px] font-medium">
              {questionUserData.question.question}
            </h1>
            <p className="p-6 text-[16px]">
              {questionUserData.question.details}
            </p>

            {questionUserData.question.attachment[0] &&
              questionUserData.question.attachment.map((value, index) => (
                <>
                  <h2 className="p-6 text-xl font-semibold">
                    Attachment {index + 1}
                  </h2>
                  <PDFView attachment={value} key={index} />
                </>
              ))}
          </div>
          {/* answer view */}
          <AnswerComponent questionId={params.id} userId={userId} />
          {/* {id ? (
            <CommentComponent
              loggedInUserId={id}
              videoId={videoUserData.video._id}
            />
          ) : (
            <h3>Login to see the comments</h3>
          )} */}
        </div>
      ) : (
        "Loading..."
      )}
    </div>
  );
}

export default DisplayQuestion;
