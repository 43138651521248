import React,{useEffect} from "react";


const GettingStarted = () => {
  return (
    <div data-aos="fade-up" className="flex items-center h-full gap-[3.2rem]">
      <div className="flex-1 self-center rounded-xl text-[2.4rem]">
        <ul className="flex justify-center flex-col uppercase">
          <li className="text-4xl  fonts-sans mt-6 ml-4">1.Informative Video</li>
          <li className="text-4xl fonts-sans mt-6 ml-2">2.Easy to make</li>
          <li className="text-4xl  fonts-sans mt-6 ml-2">3.Quick to finalize</li>
        </ul>
      </div>
      <div className="flex-grow-[2] h-[200px]  rounded-xl bg-zinc-800 text-center text-white text-4xl tracking-wide">
        video
      </div>
    </div>
  );
};

export default GettingStarted;
