import React from "react";

import { FaCheck } from "react-icons/fa";

const Step = (props) => {
  // const activeStyle = `bg-blue-600 text-[1.6rem] text-white`;
  const activeStyle = `bg-blue-600 text-white`;
  const inactiveStyle = `border-2 border-gray-200  text-gray-300`;

  return (
    <div className="flex flex-col items-center justify-center text-[1.6rem]">
      <div
        className={`mb-4 flex h-[4rem] w-[4rem] items-center justify-center rounded-full ${
          props.active || props.completed ? activeStyle : inactiveStyle
        }`}
      >
        {props.completed ? <FaCheck /> : props.index}
      </div>
      <div
        className={`${
          props.active || props.completed ? "text-black" : "text-gray-300"
        }`}
      >
        {props.label}
      </div>
    </div>
  );
};

export default Step;
