import React, { useState, useEffect } from "react";

// components
import FormLabel from "../UI/Form/FormLabel";
import Input from "../UI/Form/Input";
import { BaseURL } from "../../utils/config";

// Icons
import { IoAddCircleOutline } from "react-icons/io5";
import { FaRegTrashAlt, FaUniversity } from "react-icons/fa";

const DEGREES = ["Bachelor", "Master", "Doctoral", "Posdoc"];

// helper function -> year options
const currentYear = new Date().getFullYear();
function range(start, end) {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => end - idx);
}
const startDate = range(1940, currentYear);
const endDate = range(1940, currentYear + 30);

export const Education = (props) => {
  // open add new education form
  const [educationFormOpen, setEducationFormOpen] = useState(false);

  // open/close fetched universities resutls - university input - university query results
  const [schoolDropdownOpen, setSchoolDropdownOpen] = useState(false);
  const [school, setSchool] = useState("");
  const [showSchoolError, setShowSchoolError] = useState(false);
  const [queriedSchools, setQueriedSchools] = useState([]);

  // field of study states
  const [studyField, setStudyField] = useState("");
  const [studyFieldError, setStudyFieldError] = useState(false);

  // degree, startDate, endDate
  const [degree, setDegree] = useState(DEGREES[0]);
  const [startYear, setStartYear] = useState(startDate[0]);
  const [endYear, setEndYear] = useState(endDate[0]);

  // fetching universities from API
  useEffect(() => {
    let timerId;
    if (educationFormOpen) {
      const fetchUniversities = async () => {
        try {
          const res = await fetch(
            `http://universities.hipolabs.com/search?name=${school}`
          );
          let universities = await res.json();
          if (universities.length > 50) {
            universities = universities.slice(0, 50);
          }
          setQueriedSchools(universities);
        } catch (error) {}
      };

      if (school.length === 0) {
        // remove the last retrieved result if the input text got empty
        setQueriedSchools([]);
        return;
      }
      timerId = setTimeout(fetchUniversities, 400);
    }

    return () => clearTimeout(timerId);
  }, [school, educationFormOpen]);

  /** Handlers **/
  // edit mode handler
  const educationFormOpenHanlder = () => {
    setEducationFormOpen((prevState) => !prevState);
  };

  // shcool input
  const schoolHandler = (e) => {
    setSchool(e.target.value);

    // if the user selected a uni and wanted to change that the dropdown must get open
    // if the user started typeing then the error must disappear
    setSchoolDropdownOpen(true);
    setShowSchoolError(false);
  };

  const autoCompleteHandler = (e) => {
    setSchool(e.target.innerText);
    setSchoolDropdownOpen(false);
  };

  const schoolBlurHandler = (e) => {
    // close the dropdown onBlur -> cannot close immediately bc then the text value clicked university will
    // not be stored in the input value
    setTimeout(() => {
      setSchoolDropdownOpen(false);
    }, 100);

    if (school.length === 0) {
      // show error true
      setShowSchoolError(true);
    }
  };

  const studyFieldHandler = (e) => {
    setStudyField(e.target.value);
    setStudyFieldError(false);
  };

  const studyFieldBlurHandler = () => {
    if (studyField.length === 0) {
      // show error true
      setStudyFieldError(true);
    }
  };

  const degreeHandler = (e) => {
    setDegree(e.target.value);
  };

  const startYearHandler = (e) => {
    setStartYear(e.target.value);
  };

  const endYearHandler = (e) => {
    setEndYear(e.target.value);
  };

  //cancel & reset form function
  const resetForm = () => {
    setEducationFormOpen(false);

    setSchool("");
    setShowSchoolError(false);

    setStudyField("");
    setStudyFieldError(false);

    setDegree("");
  };

  const cancelHandler = () => {
    // setEducationFormOpen(false);

    // setSchool("");
    // setShowSchoolError(false);

    // setStudyField("");
    // setStudyFieldError(false);

    // setDegree("");
    resetForm();
  };

  // submit
  const formHandler = async (e) => {
    e.preventDefault();

    if (showSchoolError || studyFieldError) return;

    // no error, send the data to the server
    try {
      const res = await fetch(`${BaseURL}/user/${props.user.id}/education`, {
        method: "POST",
        body: JSON.stringify({
          education: {
            school,
            studyField,
            degree,
            startYear: +startYear,
            endYear: +endYear,
          },
        }),
        headers: {
          "auth-token": `${props.token}`,
          "Content-type": "application/json",
        },
      });

      if (!res.ok) throw new Error("Something went wrong!");
      // res is ok then close and reset the form -> put the cancel functions into a new function and call it reset
      // console.log(await res.json());
      const educations = await res.json();
      props.onAddEducation(educations);
      resetForm();
    } catch (error) {
      // TODO
      console.error(error);
    }
  };

  // delete
  const deleteHandler = async (id) => {
    try {
      const res = await fetch(
        `${BaseURL}/user/${props.user.id}/education/${id}`,
        {
          method: "DELETE",
          headers: {
            "auth-token": `${props.token}`,
          },
        }
      );
      const educations = await res.json();
      props.onAddEducation(educations);
    } catch (error) {
      //TODO error handling
      console.error(error);
    }
  };

  // dropdown options
  const degreeOptions = DEGREES.map((deg, i) => (
    <option key={i} value={deg}>
      {deg}
    </option>
  ));
  const startDateOptions = startDate.map((year, i) => (
    <option key={i} value={year}>
      {year}
    </option>
  ));
  const endDateOptions = endDate.map((year, i) => (
    <option key={i} value={year}>
      {year}
    </option>
  ));
  const autoCompleteUniversities = queriedSchools.map((uni, i) => (
    <li
      key={i}
      onClick={autoCompleteHandler}
      className="cursor-pointer rounded border-b-2 border-gray-200 py-4 px-3 first:bg-blue-500 first:text-white hover:bg-blue-200 hover:text-black active:bg-blue-500"
    >
      {uni.name}
    </li>
  ));

  return (
    <div>
      {/* title */}
      <div className="mb-4 flex items-center gap-4 text-[1.8rem] font-medium md:text-[2rem]">
        <h2 className="">Education</h2>
        <IoAddCircleOutline
          className="cursor-pointer text-gray-400 hover:text-black"
          onClick={educationFormOpenHanlder}
        />
      </div>

      {/* add new education form */}
      {educationFormOpen && (
        <form onSubmit={formHandler} className="mb-12 max-w-[48rem]">
          <div className="flex flex-col">
            {/* school */}
            <fieldset className="relative mb-8 flex flex-col">
              <FormLabel
                className="text-[1.6rem] font-normal"
                label="School"
                htmlFor="school"
                required
              />
              <div>
                <Input
                  required
                  type="text"
                  id="school"
                  value={school}
                  onChange={schoolHandler}
                  showError={showSchoolError}
                  placeholder="Ex: KTH University"
                  className="text-[1.4rem] font-light "
                  onBlur={schoolBlurHandler}
                  error="School is a required field."
                />
                {/* dropdown autocompletion */}
                {autoCompleteUniversities.length !== 0 && schoolDropdownOpen && (
                  <div
                    className={`absolute top-[110%] left-0 z-10 flex max-h-[20rem]
                   w-full flex-col overflow-y-scroll bg-mainBc 
                   py-[1rem] px-4 text-[1.4rem] font-medium drop-shadow-xl `}
                  >
                    <ul>{autoCompleteUniversities}</ul>
                  </div>
                )}
              </div>
            </fieldset>

            {/* Field */}
            <fieldset className="mb-8">
              <FormLabel
                className="text-[1.6rem] font-normal"
                label="Field of study"
                htmlFor="studyField"
                required
              />
              <Input
                className="text-[1.4rem]"
                required
                type="text"
                htmlFor="studyField"
                value={studyField}
                onChange={studyFieldHandler}
                showError={studyFieldError}
                placeholder="Ex: Computer vision"
                error="Field of study is a required field."
                onBlur={studyFieldBlurHandler}
              />
            </fieldset>

            {/* Degree */}
            <fieldset className="mb-8 flex flex-col">
              <FormLabel
                className="text-[1.6rem] font-normal"
                label="Degree"
                htmlFor="degree"
                required
              />
              <select
                onChange={degreeHandler}
                className="block border-2 py-[.8rem] px-2 text-[1.4rem] font-light outline-blue-500"
              >
                {degreeOptions}
              </select>
            </fieldset>

            {/* Start and end date */}
            <fieldset className="mb-4 flex gap-4">
              <div className="flex flex-1 flex-col">
                <FormLabel
                  className="text-[1.6rem] font-normal"
                  label="Start year"
                  htmlFor="startYear"
                  required
                />
                <select
                  onChange={startYearHandler}
                  className="block border-2 py-[.8rem] px-2 text-[1.4rem] font-light outline-blue-500"
                  name=""
                  id=""
                >
                  {startDateOptions}
                </select>
              </div>
              <div className="flex flex-1 flex-col">
                <FormLabel
                  className="text-[1.6rem] font-normal"
                  label="end year"
                  htmlFor="endYear"
                  required
                />
                <select
                  onChange={endYearHandler}
                  className="block border-2 py-[.8rem] px-2 text-[1.4rem] font-light outline-blue-500"
                  name=""
                  id=""
                >
                  {endDateOptions}
                </select>
              </div>
            </fieldset>
          </div>
          <div className="flex justify-start gap-4 md:justify-end">
            <button
              className="mt-2 rounded-lg bg-blue-500 py-2 px-4 text-[1.6rem] text-white hover:bg-blue-600 "
              type="submit"
            >
              Save
            </button>
            <button
              onClick={cancelHandler}
              className="mt-2 rounded-lg border-2  py-2 px-4 text-[1.6rem] text-black hover:border-black  hover:text-black"
            >
              Cancel
            </button>
          </div>
        </form>
      )}

      {/* Education content */}
      {props.educations && (
        <ol>
          {props.educations.map((edu, i) => {
            return (
              <li
                key={i}
                className="mb-4 flex items-center justify-between gap-4 rounded-lg border-2 border-gray-200 p-4 text-[1.2rem] md:max-w-[48rem]"
              >
                {/* education texts */}
                <div className="flex flex-col gap-4">
                  <div className="text-[1rem]">
                    {edu.startYear + "-" + edu.endYear}
                  </div>
                  <div className="flex flex-col gap-4 font-medium capitalize">
                    <div className="">
                      {edu.degree} - {edu.studyField}
                    </div>
                    <div className="flex items-center gap-2 font-normal">
                      <FaUniversity className="-mt-1 text-[1.4rem]" />
                      {edu.school}
                    </div>
                    {/* {edu.degree + " - " + edu.subject + ", " + edu.university} */}
                  </div>
                </div>
                {/* education edit or remove buttons */}
                <div
                  onClick={() => deleteHandler(edu._id)}
                  className="flex cursor-pointer gap-4 text-[1.6rem]"
                >
                  <FaRegTrashAlt className="text-red-500" />
                </div>
              </li>
            );
          })}
        </ol>
      )}
    </div>
  );
};
