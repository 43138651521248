import React, { useEffect } from "react";
import Aos from "aos";

import {
  ServiceWrapper,
  ServicesContainer,
  ExperienceBox,
  PromoteBox,
  ServiceMainText,
  SpeedBox,
  SpeedText,
  PromoteText,
  ExperienceText,
} from "./ServicesStyle";
import SpeedPng from "./Assets/speed.png";
import PlatformPng from "./Assets/platform.png";
import EngagedPng from "./Assets/engaged.png";

function Services() {
  useEffect(() => {
    Aos.init({ duration: 1000, offset: 200 });
  });

  return (
    <ServiceWrapper data-aos="fade-up">
      <ServiceMainText>
        Video is the Medium to communicate Science
      </ServiceMainText>
      <ServicesContainer>
        <SpeedBox>
          <img
            style={{ objectFit: "cover"}}
            width={"50%"}
            src={SpeedPng}
            alt="speed-png"
          />
          <SpeedText component="p" variant="p">
            Enhanced Speed
          </SpeedText>
        </SpeedBox>
        <ExperienceBox>
          <img
            style={{ objectFit: "cover"  }}
            width={"50%"}
            src={EngagedPng}
            alt="engaged-png"
          />
          <PromoteText component="p" variant="p">
            Promoted Engagement
          </PromoteText>
        </ExperienceBox>
        <PromoteBox>
          <img
            style={{ objectFit: "cover" }}
            width={"50%"}
            src={PlatformPng}
            alt="platform-png"
          />
          <ExperienceText component="p" variant="p">
            Personalized Learning Experience
          </ExperienceText>
        </PromoteBox>
      </ServicesContainer>
    </ServiceWrapper>
  );
}

export default Services;
