import React, { useEffect } from "react";
import {
  FooterWrapper,
  FooterBox,
  AboutContacContainer,
  ContactBox,
  AboutUsBox,
  AboutUsPara,
  AboutUsText,
  ContactUsText,
  SocialBox,
  LinkdeinLink,
  LinkdeinBox,
  MailBox,
  TelephoneBox,
  LinkdeinLinkText,
  MailText,
  TelephoneText,
  CopyRightBox,
  CopyRightText,
} from "./FooterStyles";

import Aos from "aos";

import LinkedInIcon from "@mui/icons-material/LinkedIn";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from '@mui/icons-material/LocationOn';


function Footer() {
  useEffect(() => {
    Aos.init({ duration: 1000 });
  });
  return (
    <footer>
      <FooterWrapper>
        <FooterBox data-aos="fade-in" data-aos-duration="500">
          <AboutContacContainer>
            <AboutUsBox>
              <AboutUsText component="h4" variant="h4">
                About Us
              </AboutUsText>
              <AboutUsPara component="p" variant="body2">
                We are a group of Scientists and science enthusiasts building this
                platform to help researchers communicate their knowledge.
              </AboutUsPara>
              <TelephoneBox>
                <LocationOnIcon fontSize="large" />
                <TelephoneText component="p" variant="body2">
                   Chicago,IL,USA
                </TelephoneText>
              </TelephoneBox>
            </AboutUsBox>

            <ContactBox>
              <ContactUsText>Contact Us</ContactUsText>
              <LinkdeinBox>
                <LinkdeinLink href="https://www.linkedin.com/company/viarika/">
                  <LinkedInIcon fontSize="large" sx={{ color: "white" }} />
                </LinkdeinLink>
                <LinkdeinLinkText
                  href="https://www.linkedin.com/company/viarika/"
                  underline="none"
                >
                  Linkdein
                </LinkdeinLinkText>
              </LinkdeinBox>

              <MailBox>
                <EmailIcon fontSize="large" />
                <MailText component="p" variant="body2">
                  Info@viarika.com
                </MailText>
              </MailBox>
            </ContactBox>
          </AboutContacContainer>

          <CopyRightBox>
            <CopyRightText> &#169; Copyright All Rights Reserved 2023 <span style={{color:"rgba(210, 160, 16, 1)"}}>ViaRika.com</span></CopyRightText>
          </CopyRightBox>

        </FooterBox>
      </FooterWrapper>
    </footer>
  );
}

export default Footer;