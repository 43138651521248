const faqsData = [
    {
      id: 1,
      question: "What is ViaRika?",
      answer: "ViaRika is an all-in-one platform for science communication. Our mission is to make latest research results accessible and understandable.",
      show: false,
    },
    {
      id: 2,
      question: "What do I get as a researcher to use ViaRika?",
      answer: "We believe your research deserves better communication. We offer easy-to-use webtools and guidelines to create a science video. We also offer well-categorized communities to publish your videos to maximize publicity of your research.",
      show: false,
    },
    {
      id: 3,
      question: "What do I get as a consumer on ViaRika platfrom?",
      answer:
        "If you are a science enthusiast whether it is physics, biology or robotics; you are home. Just explore your field of interests in our well-categorized communities.",
      show: false,
    },
    {
      id: 4,
      question: "Who is producing the ViaRika content?",
      answer: "Our content is produced by the person/team who performed the research, makes it uniquely authentic and scientifically accurate. ",
      show: false,
    },
    {
      id: 5,
      question: "How does it work?",
      answer:
        "After login, our webtools will be accessible to you. You can easily upload multiple clips from your lab, your experiment and work or yourself when explaining the concept. Our webtools allow you to merge, cut and insert text and make a proper science video. Then directly publish it in your research community on the platform. Please see our demo, or book a demo with us using the link here on the right corner of homepage. And as a science enthusiast, you can browse research communities and explore latest research knowledge based on your interest.",
      show: false,
    },
    {
      id: 6,
      question: "Who is target audience? ",
      answer: `The content would be for researchers, students, instructors, and engineers.`,
      show: false,
    },
    {
      id: 7,
      question: "Will the content be reviewed?  ",
      answer: `Yes, all content on the platform will be reviewed by Primary Investigator of each specific project in the relevant field before being published.`,
      show: false,
    },
    {
      id: 8,
      question: "Why Video? ",
      answer: `Video is the most effective medium for communicating complicated topics, offering multiple tools, visual aids and text narration, to enhance speed of learning.`,
      show: false,
    },
    {
      id: 9,
      question: "What is our solution for research institutes or universities? ",
      answer: `Our priority is to serve researchers at universities or research institutes, where their researhcers get access to our web-tools and their students, instructors and audience get the content. For more information, please contact us at info@viarika.com.`,
      show: false,
    },
    {
      id: 10,
      question: "Can I use the content for my own purposes, course or research?  ",
      answer: `Non-commercial use of our content is welcomed with proper attribution and citation to the creators and the platform. Published content are protected by copyright. If you have any questions, please contact us for more information.`,
      show: false,
    },
    {
      id: 11,
      question: "How can I get a demo? ",
      answer: `We absolutely love to present our tools to you, please book a demo with us at your convenience using the link here on the right corner of homepage or send us an email to info@viarika.com.`,
      show: false,
    },
  ];
  
  export default faqsData;