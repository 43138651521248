import React from 'react'
import science from '../assets/science.JPG'
import observation from '../assets/observation.JPG'
import theory from '../assets/theory.JPG'
import community from '../assets/community.JPG'


const ProcessOfScience = () => {
    return (
        <div className="flex flex-col object-cover">
            <img src={science} />
            <img src={observation}/>
            <img src={theory}/>
            <img src={community}/>
        </div>
    )
}

export default ProcessOfScience
