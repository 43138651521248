import React, { useRef, useState, useReducer } from "react";
import { AiFillMail } from "react-icons/ai";
import emailjs from "@emailjs/browser";
import Aos from "aos";
import { useEffect } from "react";

const Result = () => {
  return (
    <p className="text-md text-center font-bold tracking-wide text-gray-700">
      Your message has been successfully sent. I will contact you soon
    </p>
  );
};

const CreateVideoEditProject = () => {

  return (
    <div className=" flex h-screen w-full flex-1 flex-col gap-6 border-2 bg-white  bg-no-repeat bg-blend-multiply lg:pl-5  ">
      <div
        data-aos="fade-in"
        className="mx-auto mt-40 w-[40%] h-[400px]  max-w-6xl rounded-md border-2 border-gray-200 bg-white  p-6 shadow-lg "
      >
        <h1 className="mb-12 flex items-center justify-center text-4xl font-bold">
          Create Video Editor Project
        </h1>

        <div className="mt-5 flex w-full flex-1 flex-col gap-6 lg:pl-5 lg:pr-5">
          <input
            type="text"
            placeholder="Project Name"
            className=" font-semi-bold w-full mt-10  rounded-md border-2 border-gray-200 p-4 text-xl tracking-wide placeholder-black hover:outline-1 focus:outline-1 focus:outline-gray-300"

          />

          <input
            type="text"
            placeholder="Email"
            className="font-semi-bold w-full mt-10   rounded-md border-2 border-gray-200 p-4 text-xl tracking-wide placeholder-black hover:outline-1 focus:outline-1 focus:outline-gray-300"
          />

          <input
            type="text"
            placeholder="Field(Biology,Chemistry,Physics...)"
            className="font-semi-bold w-full mt-10   rounded-md border-2 border-gray-200 p-4 text-xl tracking-wide placeholder-black hover:outline-1 focus:outline-1 focus:outline-gray-300"
            required
          />

          <button
            className="mx-auto mt-10 block w-1/2 cursor-pointer rounded-lg bg-black from-neutral-300 p-3 text-xl tracking-wide  text-white outline-none transition-colors duration-1000 ease-in hover:bg-gray-700 hover:font-bold hover:text-white hover:shadow-md"
            type="submit"
            value="Send"
          >
            Submit
          </button>
          
        </div>
      </div>
    </div>
  );
};

export default CreateVideoEditProject;