import { useOutletContext } from "react-router-dom";
import QuestionComponent from "../../components/NewPost/AskQuestion/QuestionComponent";

function QuestionsPage() {
  const [, questionsData] = useOutletContext();

  return (
    <div className="flex h-screen">
      <div className="mb-4 max-w-[1700px]">
        <h2 className="m-8 text-left text-[22px] font-semibold">Questions</h2>
        <div className="flex flex-wrap justify-evenly gap-4">
          {questionsData.map((que, index) => (
            <QuestionComponent key={index} question={que} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default QuestionsPage;
