import React from "react";

//icons
import { BsCheckLg } from "react-icons/bs";
import { IoWarningOutline } from "react-icons/io5";

// props = type, required, htmlFor (to connect with a label), requirementMessage, placeholder
// props = error, isValid (for validation icons), isTouched (for showing validation icons),
// props = inputRef,
// props = value, onChange, showError
const Input = React.forwardRef((props, ref) => {
  // conditional elements
  const requirementMessage = props.requirementMessage && (
    <p className="mt-2 text-[1.2rem] text-gray-500 first-letter:capitalize">
      {props.requirementMessage}
    </p>
  );

  const error = props.error && (
    <small className="mt-2 text-[1.2rem] text-red-600">{props.error}</small>
  );

  //  check icon (true) - warning icon (false) - if nothing do not show anything
  let validationIcon;
  if (props.isValid === true) {
    validationIcon = (
      <BsCheckLg className="text-[1.4rem] text-green-500 md:text-[1.6rem]" />
    );
  } else if (props.isValid === false) {
    validationIcon = (
      <IoWarningOutline className="text-[1.8rem] text-red-600 md:text-[2rem]" />
    );
  }

  return (
    <div className="relative flex flex-col">
      <input
        ref={ref}
        id={props.id}
        required={props.required}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        type={props.type}
        autoComplete="off"
        placeholder={props.placeholder}
        className={`border-2 border-solid ${
          props.isValid === false && props.showError
            ? "border-red-200"
            : "border-gray-200"
        } w-full rounded py-[.8rem] pl-4 pr-16 ${
          props.className
        } outline-blue-500`}
      />
      {/* showing validation icon if isValid prop has been set. */}
      {props.isValid && (
        <span className="absolute right-6 top-1/2 inline-block -translate-y-1/2">
          {props.isTouched && validationIcon}
        </span>
      )}
      {props.error && props.showError ? error : requirementMessage}
    </div>
  );
});

export default Input;
