import { Box, styled, Typography, Link } from "@mui/material";

export const FooterWrapper = styled(Box)`
  margin-top: 5em;
  background-color: #301934;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;
  border-radius: 10px;
  @media (max-width: 650px) {
    border-radius: 0%;
  }
`;
export const FooterBox = styled(Box)`
  width: 100%;
  max-width: 80%;
  margin: 1em auto;
  display: flex;
  padding: 5em 0;
  align-items: baseline;
  justify-content: center;
  font-family: "Montserrat", sans-serif;
  flex-direction: column;
  @media (max-width: 650px) {
    max-width: 100%;
    flex-direction: column;
  }
`;

export const AboutContacContainer = styled(Box)`
  max-width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  font-family: "Poppins", sans-serif;
  @media (max-width: 650px) {
    max-width: 100%;
    flex-direction: column;
    padding: 0 2em;
  }
`;

export const AboutUsBox = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 50%;
  align-items: flex-start;
  justify-content: center;
  @media (max-width: 650px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const ContactBox = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 30%;
  @media (max-width: 650px) {
    width: 100%;
    max-width: 100%;
    margin: 3em 0;
  }
`;

export const TelephoneBox = styled(Box)`
  margin-top: 2em;
  display: flex;
  align-items: center;
`;
export const TelephoneText = styled(Typography)`
  letter-spacing: 5px;
  font-size: 1.8rem;
  color: whitesmoke;
  margin-left: 1em;
  font-family: "Poppins", sans-serif;
  &:hover {
    cursor: pointer;
    color: rgba(210, 160, 16, 1);
  }
`;

export const MailBox = styled(Box)`
  margin-top: 3em;
  display: flex;
  align-items: center;
`;
export const MailText = styled(Typography)`
  letter-spacing: 5px;
  font-size: 1.8rem;
  color: whitesmoke;
  font-family: "Poppins", sans-serif;
  margin-left: 1em;
  &:hover {
    cursor: pointer;
    color: rgba(210, 160, 16, 1);
  }
`;

export const LinkdeinBox = styled(Box)`
  margin-top: 1em;
  display: flex;
  align-items: center;
`;

export const AboutUsText = styled(Typography)`
  margin: 0.6em 0;
  color: rgba(210, 160, 16, 1);
  letter-spacing: 5px;
  font-size: 3rem;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
`;

export const ContactUsText = styled(Typography)`
  margin: 0.6em 0;
  color: rgba(210, 160, 16, 1);
  letter-spacing: 5px;
  font-size: 3rem;
  text-transform: uppercase;
  font-weight: bold;
  font-family: "Poppins", sans-serif;
`;

export const AboutUsPara = styled(Typography)`
  font-size: 1.8rem;
  letter-spacing: 2px;
  margin-top: 0.5em;
  text-align: left;
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  line-height: 40px;
  color: inherit;
`;

export const SocialBox = styled(Box)``;

export const LinkdeinLink = styled(Link)`
  font-size: 1.8rem;
  color: whitesmoke;
  letter-spacing: 2px;
  font-family: "Poppins", sans-serif;
`;

export const LinkdeinLinkText = styled(Link)`
  letter-spacing: 5px;
  font-size: 1.8rem;
  color: whitesmoke;
  font-family: "Poppins", sans-serif;
  margin-left: 1em;
  &:hover {
    cursor: pointer;
    color: rgba(210, 160, 16, 1);
  }
`;

export const CopyRightBox = styled(Box)`
  margin-top: 8em;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 650px) {
    margin-left: 2em;
    margin-top: 5em;
  }
`;

export const CopyRightText = styled(Typography)`
  letter-spacing: 5px;
  font-size: 1.8rem;
  font-weight: 600;
  @media (max-width: 650px) {
    font-size: 1.5rem;
    text-align: center;
    line-height: 30px;
  }
`;
