import React, { useState, useEffect } from "react";
import { BsQuestionCircleFill } from "react-icons/bs";
import { AiOutlineLink } from "react-icons/ai";
import { categories, options } from "../../../utils/data.js";
import FileUpload from "react-material-file-upload";
import Select from "react-select";
import { BaseURL } from "../../../utils/config.js";
import jwtDecode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import Aos from "aos"

import {
  ref,
  uploadBytes,
  getDownloadURL,
  uploadBytesResumable,
} from "firebase/storage";

import { storage } from "../../../firebase/firebase.js";
import { async } from "@firebase/util";

function AskQuestion() {

  const [question, setQuestion] = useState("");
  const [details, setDetails] = useState("");
  const [allCommunityIds, setAllCommunityIds] = useState(["", ""]);
  const [files, setFiles] = useState([]);
  const [url, setUrl] = useState("");

  const token = localStorage.getItem("user-token");
  const decoded = jwtDecode(token || "") || null;
  const id = decoded.userId;

  const [uploadedFileUrl, setUploadedFileUrl] = useState([]);
  const [curCommunity, setCurCommunity] = useState("");
  const [curCommunityId, setCurCommunityId] = useState();
  const [selectedValue, setSelectedValue] = useState();
  const [progress, setProgress] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    if (curCommunity.value) {
      const catagory = categories.filter((item) =>
        item.name.includes(curCommunity.value)
      );
      setCurCommunityId(catagory.communityId);
    }
  }, [curCommunity.value]);

  useEffect(()=> {
    Aos.init({duration:1000})
  })

  const onChangeSelect = async (newValue, catagorySelectType) => {
    // console.log(allCommunityIds);
    const getCommunityId = categories.find(
      (item) => item.name === newValue.value
    ).communityId;

    if (catagorySelectType === "1") {
      setAllCommunityIds([getCommunityId, allCommunityIds[1]]);
    } else if (catagorySelectType === "2") {
      setAllCommunityIds([allCommunityIds[0], getCommunityId]);
    }

    console.log(allCommunityIds);
  };

  const confirmFirebaseUpload = () => {
    for (let i = 0; i < files.length; i++) {
      const fileRef = ref(storage, `files/${files[i].name}`);
      const uploadTask = uploadBytesResumable(fileRef, files[i]);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        (error) => {
          alert(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            setUploadedFileUrl((prev) => [...prev, downloadURL]);
            console.log("fuile download url=" + downloadURL);
          });
        }
      );
    }
  };

  const handleSubmit = () => {
    if (question && details) {
      fetch(`${BaseURL}/question/add-question`, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          question: question,
          details: details,
          attachment: uploadedFileUrl,
          url: url,
          userId: id,
          communityIds: allCommunityIds,
        }),
      }).then(() => navigate("/home/questions", { replace: true }));
    }
  };

  return (
    <div data-aos="fade-up" className="flex w-11/12 mx-auto mt-10 justify-center bg-white">
      <div className="mb-4 flex h-fit w-full max-w-[1200px] flex-col p-5">
        <h1 className="text-[30px] text-center font-bold">Ask Question</h1>
        <p className="mt-2 text-center text-2xl font-md">
          Your question will only be shared with other researchers who are the
          member of this platform.
        </p>
        {/* form */}
        <div class="my-10">
          {/* Question */}
          <div className="flex">
            <label
              for="email"
              class="mb-2 block text-[18px] font-medium text-gray-900 dark:text-gray-300"
            >
              Question
            </label>
            <p className="text-[18px] text-red-600"> *</p>
          </div>
          <div
            className={`flex w-full items-center gap-4 rounded-lg border border-zinc-500 bg-white p-[10px] text-[1.4rem] `}
          >
            <BsQuestionCircleFill
              size="20"
              color="rgb(113 113 122 / var(--tw-border-opacity)"
            />
            <input
              type="text"
              placeholder="Type question here.."
              className="rounded-xxl w-full bg-white outline-none"
              onChange={(e) => setQuestion(e.target.value)}
            />
          </div>
          {/* Additional Details */}
          <div className="mt-10 flex">
            <label
              for="email"
              class="mb-2 block text-[18px] font-medium text-gray-900 dark:text-gray-300"
            >
              Additional Details
            </label>
            <p className="text-[18px] text-red-600"> *</p>
          </div>
          <div
            className={`flex w-full items-center gap-4 rounded-lg border border-zinc-500 bg-white p-[10px] text-[1.4rem]  `}
          >
            <textarea
              type="text"
              rows="7"
              placeholder="Include all information that you think would help your audience to understand your question better."
              className="rounded-xxl w-full bg-white outline-none"
              onChange={(e) => setDetails(e.target.value)}
            />
          </div>
          <div className="my-8">
            <label class="mt-8 mb-2 block text-[18px] font-medium text-gray-900 dark:text-gray-300">
              Select a community
            </label>
            <div className="flex w-full flex-grow flex-wrap gap-8">
              <Select
                options={options}
                onChange={(e) => onChangeSelect(e, "1")}
                className="mb-2 w-[250px]  border-zinc-800"
              />
              <Select
                options={options}
                onChange={(e) => onChangeSelect(e, "2")}
                className="mb-2 w-[250px]   border-zinc-800"
              />
            </div>
          </div>
          <div className="mt-10 flex">
            <label
              for="Attachments"
              class="mb-2 block text-[18px] font-medium text-gray-900 dark:text-gray-300"
            >
              Attachments
            </label>
          </div>
          <FileUpload
            value={files}
            onChange={setFiles}
            sx={{
              textSize: "38px",
              maxWidth: "400px",
              background: "white",
              borderColor:
                "border-color: rgb(113 113 122 / var(--tw-border-opacity))",
            }}
          />
          <button
            type="submit"
            className="mt-10 mr-4 mb-11 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-3 px-6 text-xl font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={confirmFirebaseUpload}
          >
            Confirm Upload
          </button>
          <div
            className={`my-10 flex w-full items-center gap-4 rounded-lg border border-zinc-500 bg-white p-[10px] text-[1.4rem]`}
          >
            <input
              type="text"
              placeholder="Add a url.."
              className="rounded-xxl w-full bg-white outline-none"
              onChange={(e) => setUrl(e.target.value)}
            />
            <AiOutlineLink
              size="20"
              color="rgb(113 113 122 / var(--tw-border-opacity)"
            />
          </div>
          <button
            type="submit"
            className="mr-4 mb-11 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-3 px-6 text-xl font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            onClick={handleSubmit}
          >
            Post
          </button>
        </div>
      </div>
    </div>
  );
}

export default AskQuestion;
