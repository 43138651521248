import React from "react";

// props = required, htmlFor, label, className
const FormLabel = (props) => {
  const classes = `inline-block mb-2 font-semibold capitalize ${props.className}`;

  const reqiuredStar = props.required && (
    <span className="text-red-600"> *</span>
  );
  return (
    <label className={classes} htmlFor={props.htmlFor}>
      {props.label}
      {reqiuredStar}
    </label>
  );
};

export default FormLabel;
