import React from "react";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { categories, options } from "../../../utils/data.js";
import { BaseURL } from "../../../utils/config.js";


import Select from "react-select";

import { useSelector } from "react-redux";
import jwtDecode from "jwt-decode";
import { setRef } from "@mui/material";

import axios from 'axios'

import {
  ref,
  uploadBytes,
  getDownloadURL,
  uploadBytesResumable,
} from "firebase/storage";

import { storage } from "../../../firebase/firebase.js";

import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

import PropTypes from "prop-types";
function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Box sx={{ width: "100%", mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="text.secondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

const UploadVideo = (props) => {


  console.log(window.location?.toString()?.split("=")[1])

  var editedVideoUrlFromEditor

  if(window.location.toString().includes("videoUrl")){
    editedVideoUrlFromEditor = window.location?.toString()?.split("=")[1]
  }else{
    editedVideoUrlFromEditor = "";
  }


 

  const navigate = useNavigate();
  const [abstract, setAbstract] = useState();
  const [videoTitle, setVideoTitle] = useState();
  const [uploadedVideoUrl, setUploadedVideoUrl] = useState(editedVideoUrlFromEditor.length > 0 ? editedVideoUrlFromEditor : '');
  const [uploadedImageUrl, setUploadedImageUrl] = useState();
  const [reference, setReference] = useState();
  const [curCommunity, setCurCommunity] = useState("");
  const [curCommunityId, setCurCommunityId] = useState();
  const [keywords, setKeywords] = useState("");

  const [uploadedVideo, setUploadedVideo] = useState();
  const [uploadedImage, setUploadedImage] = useState();

  console.log(uploadedVideoUrl)
  

  const [progress, setProgress] = useState(0);
  const [progress2, setProgress2] = useState(0);

  const token = localStorage.getItem("user-token");
  const decode = jwtDecode(token || "");
  const id = decode.userId;

  useEffect(() => {
    if (curCommunity.value) {
      const catagory = categories.filter((item) => {
        return item.name.includes(curCommunity.value);
      });
      setCurCommunityId(catagory[0].communityId);
      console.log(catagory[0].communityId);
      // fetch(
      //   `${BaseURL}/community/get-community-id?communityName=` +
      //     curCommunity.value
      // )
      //   .then((res) => res.json())
      //   .then((data) => setCurCommunityId(data));
    }
  }, [curCommunity.value]);

  useEffect(()=>{
    if(editedVideoUrlFromEditor.length > 0)
      props.setNewVideoAdded((prev)=>prev+1)
  },[editedVideoUrlFromEditor])

  const metadata = {
    contentType: "video/mp4",
  };

  const metadata2 = {
    contentType: "image/*",
  };

  useEffect(() => {}, [progress]);

  const confirmFirebaseUpload = () => {
    //for video upload
    const videoRef = ref(storage, `videos/${uploadedVideo.name}`);
    const uploadTask = uploadBytesResumable(videoRef, uploadedVideo, metadata);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress(progress);
      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          props.setNewVideoAdded((prev) => prev + 1);
          setUploadedVideoUrl(downloadURL);
          console.log("video download url=" + downloadURL);
        });
      }
    );

    //for image thumbnail upload
    const imageRef = ref(storage, `images/${uploadedImage.name}`);
    const uploadTask2 = uploadBytesResumable(
      imageRef,
      uploadedImage,
      metadata2
    );

    uploadTask2.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        setProgress2(progress);
      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask2.snapshot.ref).then((downloadURL) => {
          setUploadedImageUrl(downloadURL);
          console.log("image download url= " + downloadURL);
        });
      }
    );
  };

  const confirmCloudinaryUpload =async () => {


    //if we already have edited video then only upload thumbnail and set progress to 100
    if(editedVideoUrlFromEditor.length > 1)
    {
    setProgress(100);
    //url for uploading images to cloud
    const url2 = "https://api.cloudinary.com/v1_1/dkcqq57os/image/upload";
    const formData2 = new FormData();

    formData2.append("file", uploadedImage);
    formData2.append("upload_preset", "reactupload");

    //for thumbnail
    await axios.post(url2,formData2).then((res)=>setUploadedImageUrl(res.data.secure_url));

    }
    else{
    //else upload both uploaded video and thumbnail to cloudinary
    
    //url for uploading videos to cloud
    const url = "https://api.cloudinary.com/v1_1/dkcqq57os/video/upload";
    const formData = new FormData();

    formData.append("file", uploadedVideo);
    formData.append("upload_preset", "reactupload");

    const config = {

      onUploadProgress: (e) =>{
        const {loaded, total} = e
        setProgress(loaded/total*100)
      }
    }
    //for video
    await axios.post(url,formData,config).then((res)=>setUploadedVideoUrl(res.data.secure_url));
    
    //url for uploading images to cloud
    const url2 = "https://api.cloudinary.com/v1_1/dkcqq57os/image/upload";
    const formData2 = new FormData();

    formData2.append("file", uploadedImage);
    formData2.append("upload_preset", "reactupload");

    //for thumbnail
    await axios.post(url2,formData2).then((res)=>setUploadedImageUrl(res.data.secure_url));

  }

  }

  const handleSubmit = () => {
    fetch(`${BaseURL}/video/add-upload-video`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        userId: id,
        videoUrl: editedVideoUrlFromEditor.length > 0 ?  editedVideoUrlFromEditor :  uploadedVideoUrl,
        imageUrl: uploadedImageUrl,
        communityId: curCommunityId,
        title: videoTitle,
        abstract: abstract,
        reference: reference,
        keywords: keywords,
      }),
    }).then(()=>{
      props.setNewVideoAdded((prev) => prev + 1);
    }).then(() => {
      console.log("UPLOADED VIDEO ADDED TO DB");
      navigate("/home", { replace: true });
    });
  };

  return (
    <>
      <div>
        <div className="h-full w-full text-2xl">
          <div className="md:col-span-1">
            <div className="px-8 sm:px-0">
              <h3 className="text-2xl font-medium leading-6 text-gray-900">
                Upload Video
              </h3>
              <p className="mt-1 text-lg text-gray-600">
                This information will be displayed publicly so be careful what
                you share.
              </p>
            </div>
          </div>
          <div className="mt-5 h-full w-full text-2xl md:col-span-2 md:mt-0">
            <div className="shadow sm:overflow-hidden sm:rounded-md">
              <div className="space-y-6 bg-white px-4 py-5 sm:p-6">
                <div>
                  <label
                    htmlFor="about"
                    className="block text-2xl font-medium text-gray-700"
                  >
                    Title
                  </label>
                  <div className="mt-1">
                    <textarea
                      id="about"
                      name="about"
                      rows={1}
                      className="block w-full flex-1 rounded-none rounded-r-md border border-gray-500 p-1 focus:border-indigo-500 focus:ring-indigo-500  sm:text-sm"
                      placeholder=""
                      defaultValue={""}
                      onChange={(e) => setVideoTitle(e.target.value)}
                    />
                  </div>
                </div>

                <div className="grid grid-cols-3 gap-6">
                  <div className="col-span-3 sm:col-span-2">
                    <label
                      htmlFor="company-website"
                      className="block text-2xl font-medium text-gray-700"
                    >
                      Reference
                    </label>
                    <div className="mt-1 flex rounded-md shadow-sm">
                      <h1 className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-lg text-gray-500">
                        http://
                      </h1>
                      <input
                        type="text"
                        name="company-website"
                        id="company-website"
                        className="block w-full flex-1 rounded-none rounded-r-md border border-gray-500 p-4 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                        placeholder="www.example.com"
                        onChange={(e) => setReference(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="about"
                    className="block text-2xl font-medium text-gray-700"
                  >
                    Abstract
                  </label>
                  <div className="mt-1">
                    <textarea
                      id="about"
                      name="about"
                      rows={3}
                      className="block w-full flex-1 rounded-none rounded-r-md border border-gray-500 p-4 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      placeholder="Description of the video/research"
                      defaultValue={""}
                      onChange={(e) => setAbstract(e.target.value)}
                    />
                  </div>
                  <p className="mt-2 text-lg text-gray-500">
                    Brief description for research.
                  </p>
                </div>

                <div>
                  <label
                    htmlFor="about"
                    className="block text-2xl font-medium text-gray-700"
                  >
                    Keywords
                  </label>
                  <div className="mt-1">
                    <textarea
                      id="about"
                      name="about"
                      rows={1}
                      className="block w-full flex-1 rounded-none rounded-r-md border border-gray-500 p-4 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      placeholder="science, atoms, physics..."
                      defaultValue={""}
                      onChange={(e) => setKeywords(e.target.value)}
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="about"
                    className="block text-2xl font-medium text-gray-700"
                  >
                    Select Community
                  </label>
                  <Select
                    defaultValue={curCommunity}
                    options={options}
                    onChange={setCurCommunity}
                    placeholder="Drop List..."
                  />
                </div>

                <div>
                  <label class="block text-2xl font-medium text-gray-700">
                    Table of Contents image (TOC image)
                  </label>
                  <div class="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                    <div class="space-y-1 text-center">
                      <svg
                        class="mx-auto h-12 w-12 text-gray-400"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                      >
                        <path
                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>

                      <div class="flex text-2xl text-gray-600">
                        <label
                          for="file-upload"
                          class="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                        >
                          <span>Upload a file</span>
                          <input
                            id="file-upload"
                            name="file-upload"
                            type="file"
                            onChange={(e) =>setUploadedImage(e.target.files[0])}
                          />
                        </label>
                        <p class="pl-1">or drag and drop</p>
                      </div>
                      
                      <p class="text-xs text-gray-500">
                        PNG, JPG, GIF up to 10MB
                      </p>
                      
                    </div>
                  </div>
                  
                </div>

                <div>
                  <label className="block text-2xl font-medium text-gray-700">
                    Upload a Video
                  </label>
                  <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pt-5 pb-6">
                    <div className="space-y-1 text-center">
                      <div className="flex flex-col items-center text-lg text-gray-600">
                        <img
                          src="https://static.thenounproject.com/png/556238-200.png"
                          className="ml-8 h-3/4 w-3/4"
                        />
                        <label
                          htmlFor="file-upload"
                          className="relative cursor-pointer rounded-md bg-white font-medium text-indigo-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2 hover:text-indigo-500"
                        >
                          <span className="mr-4 ">Upload a file</span>
                          <input
                            type="file"
                            onChange={(e) =>
                              setUploadedVideo(e.target.files[0])
                            }
                          />
                        </label>
                        <p className="pl-1">or drag and drop</p>
                      </div>
                      <p className="text-sm text-gray-500">VIDEO MP4</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-50 px-4 py-3 text-right sm:px-6">
                <LinearProgressWithLabel
                  variant="determinate"
                  value={progress}
                  className="mb-6"
                />
                <button
                  type="submit"
                  className="mr-4 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-lg font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={confirmCloudinaryUpload}
                >
                  Upload
                </button>
                <button
                  className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-lg font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  onClick={handleSubmit}
                >
                  Publish
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UploadVideo;