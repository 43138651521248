import React from "react";
import { Link } from "react-router-dom";

export const ActionButton = (props) => {
  return (
    <Link
      className={`rounded-lg p-2 text-center transition-all duration-200 ease-in-out ${
        props.active
          ? "bg-blue-500 text-white hover:bg-blue-600"
          : "border-2 text-gray-500 hover:border-black hover:text-black"
      } ${props.className}`}
      to={props.to}
    >
      {props.text}
    </Link>
  );
};
