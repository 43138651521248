import React, { useState, useRef, useEffect } from "react";
import {
  Outlet,
  Route,
  Routes,
  useParams,
  useLocation,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { Navbar, Sidebar } from "../components";
import { BaseURL } from "../utils/config";

const HomePage = (props) => {
  const { user } = useSelector((state) => {
    return state.user;
  });

  const [videosData, setVideosData] = useState([]);
  const [questionsData, setQuestionsData] = useState([]);
  // const params = useParams();
  // const location = useLocation();

  useEffect(() => {
    const constroller = new AbortController();
    const signal = constroller.signal;
    fetch(`${BaseURL}/video/get-all-videos`, {
      signal,
    })
      .then((res) => res.json())
      .then((data) => {
        // console.log("DATA:::" + JSON.stringify(data));
        setVideosData(data);
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          console.log("slow network:" + error);
        } else {
          //todo
        }
      });

    // fetch all questions here
    fetch(`${BaseURL}/question/get-all-questions`, {
      signal,
    })
      .then((res) => res.json())
      .then((data) => {
        setQuestionsData(data);
      })
      .catch((error) => {
        if (error.name === "AbortError") {
          console.log("slow network:" + error);
        } else {
          //todo
        }
      });

    console.log("props.newVideoAdded::" + props.newVideoAdded);
    return () => {
      console.log("CLEAN UP!!!");
      constroller.abort();
    };
  }, [props.newVideoAdded]);

  console.log(videosData)

  /*
<iframe 
src="https://iframe.videodelivery.net/5d5bc37ffcf54c9b82e996823bffbb81?
poster=https%3A%2F%2Fvideodelivery.net%2F5d5bc37ffcf54c9b82e996823bffbb81%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D68s%26height%3D270" style="border: none;" height="720" width="1280" allow="accelerometer; gyroscope; autoplay;
*/
  return (
    // location.pathname === "/" ? (
    <div className="flex h-screen flex-col overflow-hidden md:flex-row">
      <Sidebar user={user} />
      <main style={{boxShadow: "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px"}} className="flex h-full flex-col overflow-y-auto bg-slate-100 md:h-screen md:flex-1 rounded-lg ">
        <div className="sticky top-0 z-10 bg-mainColor bg-opacity-[0.97]">
          <Navbar user={user} />
        </div>
        {<Outlet context={[videosData, questionsData]} />}
      </main>
    </div>
  );
};

export default HomePage;
