import React from "react";

function Button(props) {
  return (
    // button height 40px = input hieght
    <button
      onClick={props.onClick}
      type={props.type}
      className={`rounded-lg py-[.8rem] px-[1.6rem] text-[1.6rem] normal-case ${props.className}`}
      disabled={props.disabled}
    >
      {props.children}
      {props.buttonText}
    </button>
  );
}

export default Button;
