import React, { useState, useRef } from "react";
import { FaRegTrashAlt } from "react-icons/fa";
import { MdEdit } from "react-icons/md";
import Button from "../../UI/Button";
import Input from "../../UI/Form/Input";
import Aos from "aos"

const MAXIMUM_NO_OF_KEYWORDS = 6;

const Keywords = (props) => {
  const keywordInputRef = useRef();
  const [editedKeywoord, setEditedKeyword] = useState("");

  // change the default state to props.keywords
  // const [props.keywords, setKeywords] = useState(props.props.keywords);

  // if there is no keyword on editMode then we can show it
  const showAddNewKeywordBtn = !props.keywords.some(
    (keyword) => keyword.viewMode === false
  );

  const deleteKeywordHandler = (id) => {
    const newKeywordsArr = props.keywords.filter((keyword, i) => {
      return i !== id;
    });
    props.setKeywords(newKeywordsArr);
  };

  const editKeywordHandler = (id) => {
    setEditedKeyword(props.keywords[id].value);
    const newKeywordsArr = props.keywords.map((keyword, i) => {
      if (id === i) return { ...keyword, viewMode: false };
      return { ...keyword, disabled: true };
    });
    props.setKeywords(newKeywordsArr);
  };

  const addKeywordHandler = () => {
    setEditedKeyword("");
    const newKeywordArr = props.keywords.map((keyword) => {
      return { ...keyword, viewMode: true, disabled: true };
    });
    props.setKeywords([
      ...newKeywordArr,
      { value: "", viewMode: false, disabled: false },
    ]);
  };

  const saveHandler = (id) => {
    if (keywordInputRef.current.value.trim().length === 0) return;
    const newKeywordsArr = props.keywords.map((keyword, i) => {
      if (id === i)
        return {
          ...keyword,
          value: keywordInputRef.current.value,
          viewMode: true,
        };
      return { ...keyword, disabled: false };
    });
    props.setKeywords(newKeywordsArr);
  };

  const keywordOnChangeHandler = (e) => {
    setEditedKeyword(e.target.value);
  };

  const keywordsEl = props.keywords.map((keyword, i) => {
    return (
      <div key={i} className="flex items-center gap-8 text-[1.6rem]">
        {/* keyword index */}
        <div className="flex h-[4rem] w-[4rem] items-center justify-center rounded-full bg-gray-200">
          {i + 1}
        </div>
        {/* keyword text or input */}
        <div>
          {keyword.viewMode && <div className="w-[20rem]">{keyword.value}</div>}
          {!keyword.viewMode && (
            <Input
              ref={keywordInputRef}
              value={editedKeywoord}
              onChange={keywordOnChangeHandler}
            />
          )}
        </div>
        {/* keyword action buttons */}
        {!keyword.disabled && (
          <div>
            {keyword.viewMode ? (
              <div className="flex gap-8">
                <MdEdit
                  onClick={() => editKeywordHandler(i)}
                  className="cursor-pointer"
                />
                <FaRegTrashAlt
                  onClick={() => {
                    deleteKeywordHandler(i);
                  }}
                  className="cursor-pointer text-red-400"
                />
              </div>
            ) : (
              <div className="flex cursor-pointer gap-8">
                <Button
                  onClick={() => saveHandler(i)}
                  buttonText="Save"
                  className="border-2 border-gray-400  text-gray-400 hover:border-black hover:text-black"
                />
              </div>
            )}
          </div>
        )}
      </div>
    );
  });

  return (
    <div className="flex flex-col gap-12 ">
      <div className="flex flex-col gap-12">{keywordsEl}</div>
      {props.keywords.length < MAXIMUM_NO_OF_KEYWORDS && showAddNewKeywordBtn && (
        <div
          onClick={addKeywordHandler}
          className="flex cursor-pointer items-center gap-8 text-[1.6rem] text-gray-300 hover:text-black"
        >
          <div className="flex h-[4rem] w-[4rem] items-center justify-center rounded-full border-2 border-gray-200  ">
            +
          </div>
          <div>Add new keyword</div>
        </div>
      )}
      {props.keywords.length < 5 && (
        <div className="text-[1.2rem] text-red-400">
          NOTE: Number of keywords must be at least 4
        </div>
      )}

      
    </div>
  );
};

export default Keywords;
