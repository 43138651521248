import React from "react";

function UserAvtar({ userName, className }) {
  return (
    <div
      className={`undefined relative flex h-[4.8rem] w-[4.8rem] flex-shrink-0 cursor-pointer items-center justify-center rounded-full bg-orange-200 text-[1.8rem] capitalize  text-orange-800 shadow md:shadow-none ${className}`}
    >
      {userName.charAt(0).toUpperCase()}
    </div>
  );
}

export default UserAvtar;
