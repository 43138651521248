import React from "react";
import { NavLink } from "react-router-dom";

const isNotActiveStyle = `flex p-[1rem] items-center gap-4 rounded-md text-gray-500 hover:bg-gray-100 hover:text-black 
transition-all duration-200 ease-in-out capitalize`;
const isActiveStyle = `flex items-center gap-4 rounded-md bg-[#408ffe64] p-[1rem] text-blue-700`;

function NavigationLink(props) {
  return (
    <li>
      <NavLink
        to={props.to}
        className={(state) => {
          return state.isActive ? isActiveStyle : isNotActiveStyle;
        }}
        onClick={props.onClick}
        key={props.text}
      >
        {props.icon}
        {props.text}
      </NavLink>
    </li>
  );
}

export default NavigationLink;
