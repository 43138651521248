import React from "react";
import { categories } from "../../../../utils/data";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import Avatar from "@mui/material/Avatar";
import { red } from "@mui/material/colors";
import { Typography,Box,Button } from "@mui/material";
import Footer from "../Footer/Footer"
import Aos from "aos";
import { Link,useNavigate } from "react-router-dom";
import Header from "../Header/Header"

function CommunityPage() {
  const navigate = useNavigate()

  const navigateHandler = () => {
  navigate("/")
  }

  return (
    <div className="relative">
    <div data-aos="fade-in" className=" bg-white text-gray-50 bg-cover bg-center bg-no-repeat p-5">
    
      <h1 className=" my-20 text-black text-center text-6xl font-bold uppercase">
      Join your Research community
      </h1>
      <Box className=" absolute top-[7rem] ml-5 w-[120px]">
      <Button 
      sx={{fontSize:"1.2rem"}} 
      className=" uppercase p-4 bg-blue-700 hover:bg-blue-600 w-full font-sans"  
      onClick={navigateHandler} variant="contained"
      >
        go back
      </Button>
      </Box>
    
      <div className="m-10 mx-auto flex flex-wrap items-stretch justify-between">
        {categories.map((catogory) => {
          const { name, image } = catogory;
          return (
            <div
              style={{ width: "30%", margin: "1em 0" }}
              className="hover:scale-[1.05] hover:cursor-pointer"
            >
              <Card sx={{ maxWidth: "100%", height: "100%" }}>
                <Box sx={{display:"flex",alignItems:"center"}}>
                <CardHeader
                  avatar={
                    <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                      {name.charAt(0)}
                    </Avatar>
                  }
                  />
                  <Typography variant="body2" sx={{fontSize:"2rem"}}><Link to={`/home/category/${name}`}>{name}</Link></Typography>
                  </Box>
                <CardMedia
                  className="hover:opacity-60"
                  component="img"
                  sx={{ height: 200, width: "100%" }}
                  image={image}
                  alt={`${image}`}
                />
                {/* <CardContent>
              <Typography variant="body2" color="text.secondary">
               {}
              </Typography>
            </CardContent> */}
              </Card>
            </div>
          );
        })}
      </div>
    </div>
    <Footer/>
    </div>
  );
}

export default CommunityPage;