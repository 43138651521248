import React, { useState } from "react";
import { Document, Page } from "react-pdf";

function PDFView({ attachment }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  return (
    <div>
      <iframe
        src={attachment}
        // width="640"
        frameborder="0"
        className="flex aspect-video w-full items-center justify-center"
      ></iframe>
    </div>
  );
}

export default PDFView;
