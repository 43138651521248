import React,{useState, useEffect} from 'react'
import { blogQuery, blogImage } from '../utils/data'
import { client, urlFor } from '../client';
import MasonryLayout from './MasonryLayout'
import {IoNewspaperOutline} from 'react-icons/io5';

const Blog = () => {

    const [loading, setLoading] = useState(false);
    const [blog, setBlog] = useState('');
    const [blogImage, setBlogImage] = useState('');
        
    useEffect(() => {
            if(blogQuery)
        { 
        client.fetch(blogQuery)
            .then((data)=>{
                setBlog(data[0]);
                setLoading(false);
            })
        }

        if(blogImage)
        {
            client.fetch(blogImage)
            .then((image)=>{
                setBlogImage(image[0]);
                setLoading(false);
            })
        }

        },[])

    
    if(loading) return <h1>Loading...</h1>
    
    //<img className="rounded-lg w-100 item-center justify-center flex flex-col mb-5 justify-between" src={(urlFor(blog?.image).width(2000).url())}/>
    

    return (
        <div className="flex-col justify-between items-center text-center">        
        <h1 className="flex justify-center text-4xl font-bold mb-20">{ <IoNewspaperOutline className="mr-2"/>} BLOGS & NEWS </h1>
        <img className="rounded-lg w-100 item-center justify-center flex flex-col mb-5 justify-between" src={blog?.image}/>    
        <h1 className="text-xl font-bold text-indigo-600">{blog?.title}</h1>
        <p className="flex">{blog?.content}</p>
        
        </div>
    )
}

export default Blog
