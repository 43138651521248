import React, { useState, useEffect } from "react";
import { BaseURL } from "../utils/config";

import { useSelector } from "react-redux";

// Components
import { Bio } from "./Profile/Bio";

// Icon
import { BsFillPersonFill } from "react-icons/bs";
import { Education } from "./Profile/Education";

const randomImage =
  "https://source.unsplash.com/1600x900/?science,biology,medicine,computers,physics,chemistry";

const UserProfile = () => {
  // retrieving user data
  const { token, user } = useSelector((state) => state.user);

  const [error, setError] = useState(false);

  // store profile data
  const [profile, setProfile] = useState({});

  const onAddEducation = (educations) => {
    setProfile((prevState) => ({ ...prevState, education: educations }));
  };

  // fetching user profile data
  useEffect(() => {
    const fetchUserProfileData = async () => {
      const tokens = JSON.parse(localStorage.getItem("user-token"));
      try {
        const res = await fetch(`${BaseURL}/user/${user.id}/profile`, {
          headers: {
            "auth-token": `Bearer ${tokens}`,
          },
        });

        if (!res.ok)
          throw new Error("Something went wrong in fetching your data");

        // res was ok store the data in states
        const profileData = await res.json();
        const profile = { ...profileData };
        setProfile(profile);
      } catch (error) {
        setError(true);
      }
    };
    if (user) {
      fetchUserProfileData();
    }
  }, [user, token]);

  if (user) {
    if (error) {
      return (
        <div className="bg-red-200 p-4 text-center text-[1.6rem]">
          Failed to fetch your data!
        </div>
      );
    }
    return (
      <div className="rounded-lg bg-white">
        {/* user profile*/}
        <div className="relative">
          <div className=" h-[20rem] md:h-[25rem]">
            <img
              className="h-full w-full rounded-md object-cover"
              src={randomImage}
              alt={user.firstname + " " + user.lastname}
            />
          </div>
          {/* profile pic */}
          <div className="absolute left-0 top-full flex h-[6.4rem] w-[6.4rem] -translate-y-1/2 translate-x-1/4 items-center justify-center rounded-full bg-blue-200 text-[3rem] text-white shadow-md md:h-[10rem] md:w-[10rem] md:text-[5rem]">
            {user.image !== "" ? (
              <img
                className="h-full w-full rounded-full object-cover"
                src={user.image}
                alt=""
              />
            ) : (
              <BsFillPersonFill />
            )}
          </div>
        </div>

        {/* user info*/}
        <div className="flex flex-col gap-8 py-20 px-[1.6rem] md:py-32 md:px-[2.5rem]">
          {/* name */}
          <h1 className="text-[2rem] font-semibold capitalize md:text-[2.4rem]">
            {user.firstname + " " + user.lastname}
          </h1>

          {/* Bio */}
          {user.isResearcher && (
            <Bio token={token} user={user} bio={profile.bio} />
          )}

          {/* Education */}
          {user.isResearcher && (
            <Education
              token={token}
              user={user}
              onAddEducation={onAddEducation}
              educations={profile.education}
            />
          )}
        </div>
      </div>
    );
  }

  return <div>Loading...</div>;
};

export default UserProfile;
