import React, { useState } from "react";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import faqsData from "./faqsData";
import { useEffect } from "react";
import Aos from "aos";

function FAQCCORDIAN({ question, answer, id, index }) {
  
  const [dataArray, setDataArray] = useState(faqsData);

  const handleIconClick = (id) => {
    console.log("clicked");
    setDataArray((prevDataArray) => {
      return prevDataArray.map((item) => {
        return item.id === id ? { ...item, show: !item.show } : item;
      });
    });
  };

  useEffect(() => {
    console.log(dataArray);
  }, [dataArray]);

  return (
    <div className="my-10 w-fit rounded-md bg-white py-5 px-7 text-black shadow-md">
      <div className="flex items-center">
        {!dataArray[index].show ? (
          <AddCircleOutlineIcon
            onClick={() => handleIconClick(id)}
            fontSize="large"
            className="hover:cursor-pointer"
          />
        ) : (
          <RemoveCircleOutlineIcon
            onClick={() => handleIconClick(id)}
            fontSize="large"
            className="hover:cursor-pointer"
          />
        )}
        <span className="question ml-5 text-[2rem] capitalize">{question}</span>
      </div>
      {dataArray[index].show && (
        <p className="my-4 text-gray-800 py-10 text-[1.5rem] tracking-wide ">
          {answer}
        </p>
      )}
    </div>
  );
}

export default FAQCCORDIAN;