import React, { useEffect } from "react";
import Aos from "aos";


function Card(props) {
  useEffect(()=> {
    Aos.init({duration:300})
  })

  return <div data-aos="slide-left" className={`h-fit  ${props.className}`}>{props.children}</div>;
}

export default Card;
