import React, { Fragment } from "react";
import { Outlet } from "react-router-dom";

// import shareVideo from "../assets/share.mp4";

function AuthPage() {
  return (
    <Fragment>
      <div className="bg-gray-600">
        <div className="h-screen w-screen">
          {/* <video
            src={shareVideo}
            type="video/mp4"
            control={"false"}
            muted
            className="hidden h-full w-full object-cover md:block"
          /> */}
          <div className="flex w-screen items-center bg-gray-600 md:absolute md:top-0 md:left-0 bg-[url('https://images.unsplash.com/photo-1511174511562-5f7f18b874f8?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80')]  bg-center bg-left-top bg-no-repeat bg-blend-multiply bg-fixed">
            <Outlet />
          </div>
        </div>
      </div>
    </Fragment>
  );
}

export default AuthPage;
