import React, { useEffect } from "react";
import Aos from "aos";
import {
  ResearchContainer,
  ResearchItem,
  ResearchMainText,
  ResearchWrapper,
  ResearchText,
} from "./ResearchStyles";
import WebPng from "./Assets/web.png";
import LocationPng from "./Assets/location.png";
import BookPng from "./Assets/book.png";

function Research() {
  useEffect(() => {
    Aos.init({ duration: 500, offset: 200 });
  });
  return (
    <ResearchWrapper data-aos="fade-in">
      <ResearchMainText>
        All-In-One Platform for Research Believers
      </ResearchMainText>

      <ResearchContainer data-aos="slide-right"  >
        <ResearchItem>
          <img
            style={{ objectFit: "cover" }}
            width={"20%"}
            src={WebPng}
            alt="web-png"
          />
          <ResearchText>
            Well categorized platform to facilitate accessibility for science
            enthusiasts
          </ResearchText>
        </ResearchItem>
        <ResearchItem>
          <img
            style={{ objectFit: "cover" }}
            width={"20%"}
            src={LocationPng}
            alt="location-png"
          />
          <ResearchText>
            Easy-to-use webtools to create science videos in matter of minutes
          </ResearchText>
        </ResearchItem>
        <ResearchItem>
          <img
            style={{ objectFit: "cover" }}
            width={"20%"}
            src={BookPng}
            alt="book-png"
          />
          <ResearchText>
            Related guidelines to communicate clearly to your audience
          </ResearchText>
        </ResearchItem>
      </ResearchContainer>
    </ResearchWrapper>
  );
}

export default Research;
