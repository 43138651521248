import React, { useState, Suspense, lazy, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";
import { retrieveToken } from "./store/userSlice";
import LandingPage from "./components/UI/LandingPage/LandingPage"
import HomePage from "./pages/HomePage";
import AuthPage from "./pages/AuthPage";
import LoginForm from "./components/Auth/LoginForm";
import SignupForm from "./components/Auth/SignupForm";
import { CreatePin, PinDetail, UserProfile } from "./components";
import About from "./components/About";
import ContactUs from "./components/ContactUs";
import Booking from "./components/Booking";
import ProcessOfScience from "./components/ProcessOfScience";
import Blog from "./components/Blog";
import Settings from "./components/Settings";
import NewVideo from "./components/NewPost/NewVideo/NewVideo";
import FeedPage from "./pages/nested-pages/FeedPage";
import SelectVideoType from "./components/NewPost/SelectVideoType";
import UploadVideo from "./components/NewPost/UploadVideo/UploadVideo";
import AskQuestion from "./components/NewPost/AskQuestion/AskQuestion";
import QuestionsPage from "./pages/nested-pages/QuestionsPage";
import DisplayQuestion from "./components/NewPost/AskQuestion/DisplayQuestion";
import CommunityPage from "./components/UI/LandingPage/Community/Community";
import ForgotPassword from "./components/AuthReset/ForgotPassword";
import EmailVerification from "./components/AuthReset/EmailVerification";
import CreateVideoEditProject from './components/CreateVideoEditProject';

// import WatchVideo from "./pages/nested-pages/WatchVideo";

// lazy loading for better performance
const WatchVideo = lazy(() => import("./pages/nested-pages/WatchVideo"));

const App = () => {
  const dispatch = useDispatch();
  dispatch(retrieveToken());

  // Keep track if new video added, should update useEffect
  const [newVideoAdded, setNewVideoAdded] = useState(0);

  useEffect(() => {
  }, [newVideoAdded])

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <Routes>
        <Route  path="/" element={ <LandingPage/> }></Route>
        <Route path="home" element={<HomePage newVideoAdded={newVideoAdded} />}>
          <Route index element={<FeedPage />} />
          <Route path="/home/questions" element={<QuestionsPage />} />
          <Route path="questions/:id" element={<DisplayQuestion />} />
          <Route path="/home/about" element={<About />} />
          <Route path="/home/blog" element={<Blog />} />
          <Route path="/home/contact-Us" element={<ContactUs />} />
          <Route path="/home/booking-an-appointment" element={<Booking />} />
          <Route path="/home/user/:id/profile" element={<UserProfile />} />
          <Route path="process-of-science" element={<ProcessOfScience />} />
          {/* create pin means upload new video page */}
          <Route path="create-pin" element={<CreatePin />} />
          {/* profile page -> when the user wants to update his profile */}
          <Route path="/home/settings" element={<Settings />} />
          {/* specific video page */}
          <Route path="pin-detail/:pinId" element={<PinDetail />} />
          <Route path="category/:categoryId" element={<FeedPage />} />
          {/* <Route
            path="new-video"
            element={<NewVideo setNewVideoAdded={setNewVideoAdded} />}
          /> */}
          <Route path="watch/:id" element={<WatchVideo />} />
          <Route
            path="/home/new-video"
            element={<NewVideo setNewVideoAdded={setNewVideoAdded} />}
          />
          <Route path="/home/select-upload" element={<SelectVideoType />} />
          <Route
            path="/home/upload-video"
            element={<UploadVideo setNewVideoAdded={setNewVideoAdded} />}
          />
          <Route path="/home/ask-question" element={<AskQuestion />} />
        </Route>

        <Route path="/account/*" element={<AuthPage />}>
          <Route path="login" element={<LoginForm />} />
          <Route path="signup" element={<SignupForm />} />
        </Route>

        <Route path="email-verification" element={<EmailVerification/>} />
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="/home/select-upload/create-edit-project" element={<CreateVideoEditProject />} />
        
        
        <Route path="*" element={<Navigate to="/" replace />} />
        <Route path="/landing/signup" element={ <SignupForm/> }></Route>
        <Route path="/landing/login" element={ <LoginForm/> }></Route>
        <Route path="/landing/blog" element={ <Blog/> }></Route>
        <Route path="/landing/home" element={ <HomePage/> }></Route>
        <Route path="/landing/ask-question" element={ <AskQuestion/> }></Route>
        <Route path="/landing/contact" element={ <ContactUs/> }></Route>
        <Route path="/landing/home/select-upload" element={<SelectVideoType/>}></Route>
        <Route path="/landing/community" element={<CommunityPage/>}></Route>
      </Routes>
    </Suspense>
  );
};

export default App;
