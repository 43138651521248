import React, { useState, useEffect, useRef } from "react";
import Step from "./Step";

const ProgressSteps = ({ steps, currentStep }) => {
  const [newStep, setNewStep] = useState([]);
  const stepRef = useRef();

  const updateStep = (stepNumber, stepsArr) => {
    // because we want to update the array which it starts from zero
    const currentStep = stepNumber - 1;

    // using spread operator to not mutating the main array
    const newSteps = stepsArr;

    // updaing steps state due to user click on progressSteps actions
    let count = 0;
    while (count < newSteps.length) {
      // current step
      if (count === currentStep) {
        newSteps[count] = {
          ...newSteps[count],
          active: true,
          completed: false,
        };
        count++;
      }
      // step completed
      else if (count < currentStep) {
        newSteps[count] = {
          ...newSteps[count],
          active: false,
          completed: true,
        };
        count++;
      }
      // step pending
      else {
        newSteps[count] = {
          ...newSteps[count],
          active: false,
          completed: false,
        };
        count++;
      }
    }
    return newSteps;
  };

  useEffect(() => {
    // creating steps with default values
    const stepsState = steps.map((step, i) => {
      return Object.assign(
        {},
        {
          label: step,
          completed: false,
          active: i === 0 ? true : false,
        }
      );
    });

    // why using ref here? what is the reason?
    stepRef.current = stepsState;
    const updatedSteps = updateStep(currentStep, stepRef.current);
    setNewStep(updatedSteps);
  }, [steps, currentStep]);

  const renderSteps = newStep.map((step, i) => {
    return (
      <Step
        key={i}
        label={step.label}
        completed={step.completed}
        active={step.active}
        index={i + 1}
      />
    );
  });

  return <div className="mb-20 flex justify-between">{renderSteps}</div>;
};

export default ProgressSteps;
