import React from "react";
import Card from "./Card";

function FormCard(props) {
  return (
    <Card className=" ml-auto w-full  md:w-4/5 md:max-w-4xl bg-white p-2 md:p-12 border-2">
      {props.children}
    </Card>
  );
}

export default FormCard;
