import React from "react";

import {
  HomeWrapper,
} from "./LandingPageStyles";

import "./extra.css";
import Hero from "./Hero/Hero";
import Services from "./Services/Services";
import Research from "./Research/Research";
import Header from "./Header/Header";
import "./App.css"
import Footer from "./Footer/Footer";
import Carousel from "./Carousel/Carousel";
import Calendly from "./Calendly/Calendly.js";
import FAQS from "./FAQS/FAQS";

function LandingPage() {
  return (
    <>
      <HomeWrapper>
        <Header />
        <Hero />
        <Services />
        {/* <Carousel/> */}
        <Research />
      </HomeWrapper>
      <HomeWrapper>
      <FAQS/>
      </HomeWrapper>
      <Footer/>
      <Calendly/>
    </>
  );
}

export default LandingPage;