import { createSlice } from "@reduxjs/toolkit";
import { useSelector, useDispatch } from "react-redux";
import { BaseURL } from "../utils/config";
// jwt
import jwt_decode from "jwt-decode";
import jwtDecode from "jwt-decode";

const userSlice = createSlice({
  name: "user",
  initialState: {
    token: null,
    isLoggedIn: false,
    user: null,
  },
  reducers: {
    login: (state, action) => {
      state.token = action.payload.token;
      state.isLoggedIn = true;
      state.user = action.payload.user;
    },
    logout: (state) => {
      state.isLoggedIn = false;
      state.token = null;
      state.user = null;
    },
  },
});

export const retrieveToken = () => {
  return async (dispatch) => {
    // if there was no token just return and continute with initial slice state
    const token = await localStorage.getItem("user-token");
    console.log(token);
    if (!token) return;
    const decoded = jwt_decode(token || "") || null;
    const id = decoded.userId;
    // else decode the token and send request to the server to get userInfo
    try {
      const res = await fetch(`${BaseURL}/user/${id}`, {
        method: "GET",
        headers: {
          "auth-token": token,
          Authorization: `Bearer ${token}`,
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      });

      const userData = await res.json();

      const data = { token: token, user: userData.user };
      dispatch(userActions.login(data));

      console.log("USER DETAILS: " + JSON.stringify(userData.user));

      // const res = await
    } catch (error) {
      console.error("user id " + id);
      console.error("Invalid Token!");
    }
  };
};

export const userActions = userSlice.actions;

export default userSlice;
