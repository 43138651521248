import React from "react";
import { useParams, useOutletContext, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useState } from "react";
import { BaseURL } from "../../utils/config";
import FeedCard from "../../components/UI/FeedCard";

function FeedPage() {
  const [videosData, questionsData] = useOutletContext();
  const params = useParams();
  const isLocationRoot = useLocation().pathname === "/home";
  const [fetchedCategoryId, setFetchedCategoryId] = useState("");

  // console.log("questionsData" + JSON.stringify(questionsData));

  // Fetch the idea and update the state, get the Category ID by Category Name
  useEffect(() => {
    const constroller = new AbortController();
    const signal = constroller.signal;

    if (params.categoryId) {
      console.log("---FeedPage\n\n");
      fetch(
        `${BaseURL}/community/get-community-id?communityName=` +
          params.categoryId,
        { signal }
      )
        .then((res) => res.json())
        .then((data) => {
          setFetchedCategoryId(data);
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      console.log("Empty 'params.categoryId'");
    }
    return () => {
      constroller.abort();
    };
  }, [params.categoryId]);

  return (
    <>
      {videosData[0] ? (
        <div className="mb-4 max-w-[1700px]">
          {isLocationRoot ? (
            <>
              <h2
                className="m-8 text-center text-4xl font-semibold uppercase"
              >
                Explore recent knowledge
              </h2>
              <div
                className="mx-auto flex w-11/12 flex-wrap items-stretch justify-between"
              >
                {videosData.map((v, index) => (
                  <FeedCard key={`div_video_${index}`} videoInfo={v} />
                ))}
              </div>
            </>
          ) : (
            <>
              <h2 className="m-8 text-left text-[22px] font-semibold">
                {params.categoryId}
              </h2>
              <div className="flex flex-wrap justify-evenly">
                {videosData
                  .filter((vi) => vi.communityId === fetchedCategoryId)
                  .map((v, index) => (
                    <FeedCard key={`div_video_${index}`} videoInfo={v} />
                  ))}
              </div>
            </>
          )}
        </div>
      ) : (
        "Loading.."
      )}
    </>
  );
}

export default FeedPage;
