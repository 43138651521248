import React, { useState } from "react";
import { Navigate } from "react-router-dom";
import generatedVideo from "./videos/generatedVideo.mp4";
import { options } from "../../../utils/data.js";
import { BaseURL } from "../../../utils/config";

import Select from "react-select";
import { useEffect } from "react";

import ClipLoader from "react-spinners/ClipLoader";

const ShareVideo = (props) => {
  const [videoTitle, setVideoTitle] = useState();
  const [reference, setReference] = useState();
  const [curCommunity, setCurCommunity] = useState("");
  const [curCommunityId, setCurCommunityId] = useState();

  useEffect(() => {
    props.setCommunity(curCommunity);
    props.setCommunityId(curCommunityId);
    props.setReference(reference);
    props.setVideoTitle(videoTitle);
  }, [curCommunity, curCommunityId, reference, videoTitle]);

  useEffect(() => {
    if (curCommunity.value) {
      fetch(
        `${BaseURL}/community/get-community-id?communityName=` +
          curCommunity.value
      )
        .then((res) => res.json())
        .then((data) => setCurCommunityId(data));
    }
  }, [curCommunity.value]);

  const override = {
    display: "block",
    margin: "0 auto",
    borderColor: "red",
  };

  return (
    <div className="flex h-full flex-col gap-[2.2rem]">
      WAIT UNTIL VIDEO IS UPLOADED TO SHARE
      {props.uploadLoading ? (
        <ClipLoader
          color="#ffffff"
          loading={true}
          cssOverride={override}
          size={150}
        />
      ) : (
        <h1 className="text-2xl">
          VIDEO PROCESSED SUCCESSFULLY, CLICK SHARE TO CONTINUE
        </h1>
      )}
      <h1 className="text-2xl">Video Title</h1>
      <textarea
        className="h-full w-full rounded border-2 border-gray-300 p-6 text-[1.4rem]"
        onChange={(e) => setVideoTitle(e.target.value)}
      />
      <h1 className="text-2xl">Abstract</h1>
      <textarea
        className="h-full w-full rounded border-2 border-gray-300 p-6 text-[1.4rem]"
        readonly
        value={props.abstract}
      />
      <h1 className="text-2xl">Keywords</h1>
      <input
        className="w-full rounded border-2 border-gray-300 p-6 text-[1.4rem]"
        value={props.keywords.map((keyword) => keyword.value + " - ")}
      />
      <h1 className="text-2xl">Reference</h1>
      <input
        placeholder="reference of the research"
        className="w-full rounded border-2 border-gray-300 p-6 text-[1.4rem]"
        onChange={(e) => setReference(e.target.value)}
      />
      <h1 className="text-2xl">Select Community</h1>
      <Select
        defaultValue={curCommunity}
        options={options}
        onChange={setCurCommunity}
      />
    </div>
  );
};

export default ShareVideo;
