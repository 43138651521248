import { useReducer } from "react";

const defaultState = {
  value: "",
  isTouched: false,
  isValid: null,
  error: "",
  showError: false,
};

const useInput = (inputValidationFn, generateErrorMessageFn) => {
  const inputReducerFn = (state, action) => {
    if (action.type === "USER_INPUT") {
      // validate input
      const inputIsTouched = true;
      const inputIsValid = inputValidationFn(
        action.value,
        action.passwordInputValue
      );

      // set error message
      let errorMessage = "";
      if (state.showError && !inputIsValid) {
        errorMessage = generateErrorMessageFn(action.value);
      }

      return {
        value: action.value,
        isTouched: inputIsTouched,
        isValid: inputIsValid,
        error: errorMessage,
        showError: state.showError,
      };
    }

    if (action.type === "INVALID_INPUT") {
      // show error after first submission
      const showError = true;
      const errorMessage = generateErrorMessageFn(state.value);

      return {
        value: state.value,
        isTouched: state.isTouched,
        isValid: state.isValid,
        error: errorMessage,
        showError: showError,
      };
    }

    if (action.type === "INPUT_RESET") {
      return defaultState;
    }

    return defaultState;
  };

  const [inputState, dispatchInput] = useReducer(inputReducerFn, defaultState);

  return { inputState, dispatchInput };
};

export default useInput;
