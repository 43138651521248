import React,{useState, useEffect, useLayoutEffect, CSSProperties} from 'react';
import generatedVideo from './videos/generatedVideo.mp4'
import ClipLoader from "react-spinners/ClipLoader";

const PreviewVideo = (props) =>{

    useEffect(()=>{

    },[generatedVideo])

    const override = {
      display: "block",
      margin: "0 auto",
      borderColor: "red",
    };

    return(
        <div className="flex h-full gap-[3.2rem]">
        <div className="flex-1 self-center rounded-xl text-[2.4rem]">
          <div className="font-bold">Click Next to Create Post:</div>
          <ul>
            <li>Preview Video</li>
          </ul>
        </div>
          <h1 className="flex font-bold">Loading New Generating Video...</h1>
          {
            props.isLoading ? (
              <ClipLoader color="#ffffff" loading={true} cssOverride={override} size={150} />
            ):(
            <h1 className="text-2xl">VIDEO GENERATED SUCCESSFULLY</h1>
            )
          }
      </div>

    )
}

export default PreviewVideo