import React, { useState, useReducer, useEffect } from "react";
import { BaseURL } from "../../utils/config";

// Icons
import { MdEdit } from "react-icons/md";

// Reducer fn
const bioReducerFn = (state, action) => {
  if (action.type === "BIO_SERVER") {
    return { onLocal: action.value, onServer: action.value };
  }

  if (action.type === "USER_INPUT") {
    return { onLocal: action.value, onServer: state.onServer };
  }

  if (action.type === "CANCEL_INPUT") {
    return { onLocal: state.onServer, onServer: state.onServer };
  }

  return { onLocal: "", onServer: "" };
};

// props -> token, user, bio (onDb)
export const Bio = (props) => {
  // bio states (edit-value)
  const [bioIsOnEditMode, setBioIsOnEditMode] = useState(false);
  const [bio, dispatchBio] = useReducer(bioReducerFn, {
    onLocal: props.bio ? props.bio : "",
    onServer: props.bio ? props.bio : "",
  });

  useEffect(() => {
    if (props.bio) {
      dispatchBio({ type: "BIO_SERVER", value: props.bio });
    }
  }, [props.bio]);

  // edit mode handler
  const bioEditModeHandler = () => {
    setBioIsOnEditMode((prevState) => !prevState);
  };

  // edit value dispatches
  const bioIsOnEditModeCancelHandler = () => {
    dispatchBio({ type: "CANCEL_INPUT" });
    setBioIsOnEditMode(false);
  };

  const bioChangeHandler = (e) => {
    dispatchBio({ type: "USER_INPUT", value: e.target.value });
  };

  const saveBioChangeHandler = async (e) => {
    e.preventDefault();

    if (bio.onLocal === bio.onServer) {
      setBioIsOnEditMode(false);
      return;
    }

    // save the bio change
    try {
      const res = await fetch(`${BaseURL}/user/${props.user.id}/bio`, {
        method: "PATCH",
        body: JSON.stringify({ newBio: bio.onLocal }),
        headers: {
          "auth-token": `${props.token}`,
          "Content-type": "application/json",
        },
      });

      if (!res.ok) throw new Error("Something went wrong!");

      // when the response was ok, I should set the user.bio = user.newBio
      const { newBio } = await res.json();
      dispatchBio({ type: "BIO_SERVER", value: newBio });
      setBioIsOnEditMode(false);
    } catch (error) {
      console.error(error);
      setBioIsOnEditMode(false);
    }
  };

  return (
    <div className="mainbc mb-12">
      {/* title - icon */}
      <div className="mb-4 flex items-center gap-4 text-[1.8rem] font-medium md:text-[2rem]">
        <h2 className="">Bio</h2>
        <MdEdit
          className="cursor-pointer text-gray-400 hover:text-black"
          onClick={bioEditModeHandler}
        />
      </div>

      {/* bio body */}
      <div className="text-[1.2rem] md:text-[1.4rem]">
        {bioIsOnEditMode ? (
          <form onSubmit={saveBioChangeHandler}>
            <div className="relative">
              <textarea
                onChange={bioChangeHandler}
                className="min-h-[15rem] w-full resize-none rounded-lg border-2 border-gray-300 p-4 pb-8 focus:shadow-md "
                value={bio.onLocal}
                maxLength="500"
                placeholder={"Introduce yourself..."}
              ></textarea>
              <div
                className={`absolute right-6 bottom-6 font-medium ${
                  bio.onLocal.length === 500 ? "text-red-600" : ""
                }`}
              >{`${bio.onLocal.length}/500`}</div>
            </div>
            <div className="flex justify-end gap-4">
              <button
                className="mt-2 rounded-lg bg-blue-500 py-2 px-4 text-[1.6rem] text-white hover:bg-blue-600"
                type="submit"
              >
                Save
              </button>
              <button
                onClick={bioIsOnEditModeCancelHandler}
                className="mt-2 rounded-lg border-2  py-2 px-4 text-[1.6rem] text-black hover:border-black  hover:text-black"
              >
                Cancel
              </button>
            </div>
          </form>
        ) : (
          <p className="">{bio.onServer}</p>
        )}
      </div>
    </div>
  );
};
