import React from "react";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { userActions } from "../../store/userSlice";

// icon
import { MdExpandLess } from "react-icons/md";

export const Profile = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [subMenuIsOpen, setSubMenuIsOpen] = useState(false);

  const subMenuOpenHandler = () => {
    setSubMenuIsOpen((prevState) => !prevState);
  };

  const subMenuCloseHandler = () => {
    setSubMenuIsOpen(false);
  };

  const logoutHandler = () => {
    dispatch(userActions.logout());
    localStorage.removeItem("user-token");
    navigate("/", { replace: false });
  };

  return (
    <div
      onClick={subMenuOpenHandler}
      className="flex items-center gap-2 hover:cursor-pointer"
    >
      <div
        className={`relative flex h-[4.8rem] w-[4.8rem] flex-shrink-0 items-center 
        justify-center rounded-full bg-blue-200 text-[1.8rem] capitalize text-blue-800
        shadow  md:shadow-none ${props.className}`}
      >
        {props.image ? (
          <img
            className="h-full w-full rounded-full object-cover"
            src={props.image}
            alt={props.name}
          />
        ) : (
          `${props.name.slice(0, 1)}`
        )}

        {/* Sub-menu */}
        {subMenuIsOpen && (
          <ul
            onMouseLeave={subMenuCloseHandler}
            className="absolute top-[110%] -left-[100%] rounded-lg bg-white py-4 px-4 text-black shadow-md md:left-0"
          >
            <li>
              <Link
                className="p flex items-center rounded p-4 hover:bg-gray-100"
                to={`/home/user/${props.userId}/profile`}
              >
                Profile
              </Link>
              <div
                className="p flex items-center rounded p-4 hover:bg-gray-100"
                onClick={logoutHandler}
              >
                Logout
              </div>
            </li>
          </ul>
        )}
      </div>

      {/* expand arrow */}
      <div className="hidden text-[2rem] md:block">
        <MdExpandLess className="rotate-180" />
      </div>
    </div>
  );
};
